import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';

import KTLayoutQuickSearch from '@themes/metronic/assets/js/layout/extended/quick-search';
import KTLayoutQuickNotifications from '@themes/metronic/assets/js/layout/extended/quick-notifications';
import KTLayoutQuickActions from '@themes/metronic/assets/js/layout/extended/quick-actions';
import KTLayoutQuickCartPanel from '@themes/metronic/assets/js/layout/extended/quick-cart';
import KTLayoutQuickPanel from '@themes/metronic/assets/js/layout/extended/quick-panel';
import KTLayoutQuickUser from '@themes/metronic/assets/js/layout/extended/quick-user';
import { KTUtil } from '@themes/metronic/assets/js/components/util';
import { LayoutService } from '@themes/metronic/app/_metronic/core';
import { AuthService } from '@core/services/auth.service';
import { User } from '@core/model/user/user';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, AfterViewInit {
  user$: Observable<User>;
  // tobbar extras
  extraSearchDisplay: boolean;
  extrasSearchLayout: 'offcanvas' | 'dropdown';
  extrasNotificationsDisplay: boolean;
  extrasNotificationsLayout: 'offcanvas' | 'dropdown';
  extrasQuickActionsDisplay: boolean;
  extrasQuickActionsLayout: 'offcanvas' | 'dropdown';
  extrasCartDisplay: boolean;
  extrasCartLayout: 'offcanvas' | 'dropdown';
  extrasQuickPanelDisplay: boolean;
  extrasLanguagesDisplay: boolean;
  extrasUserDisplay: boolean;
  extrasUserLayout: 'offcanvas' | 'dropdown';
  // layout
  asideSecondaryDisplay: boolean;
  asideSelfMinimizeToggle: boolean;

  constructor(
    private layout: LayoutService,
    public notificationService: NotificationService,
    private auth: AuthService
  ) {
    this.user$ = this.auth.user$.asObservable();
  }

  ngOnInit(): void {
    // topbar extras
    this.extraSearchDisplay = this.layout.getProp('extras.search.display');
    this.extrasSearchLayout = this.layout.getProp('extras.search.layout');
    this.extrasNotificationsDisplay = this.layout.getProp(
      'extras.notifications.display'
    );
    this.extrasNotificationsLayout = this.layout.getProp(
      'extras.notifications.layout'
    );
    this.extrasQuickActionsDisplay = this.layout.getProp(
      'extras.quickActions.display'
    );
    this.extrasQuickActionsLayout = this.layout.getProp(
      'extras.quickActions.layout'
    );
    this.extrasCartDisplay = this.layout.getProp('extras.cart.display');
    this.extrasCartLayout = this.layout.getProp('extras.cart.layout');
    this.extrasLanguagesDisplay = this.layout.getProp(
      'extras.languages.display'
    );
    this.extrasUserDisplay = this.layout.getProp('extras.user.display');
    this.extrasUserLayout = this.layout.getProp('extras.user.layout');
    this.extrasQuickPanelDisplay = this.layout.getProp(
      'extras.quickPanel.display'
    );
    // layout
    this.asideSecondaryDisplay = this.layout.getProp('aside.secondary.display');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
  }

  ngAfterViewInit(): void {
    KTUtil.ready(() => {
      // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
      // Add 'implements AfterViewInit' to the class.
      if (this.extraSearchDisplay && this.extrasSearchLayout === 'offcanvas') {
        KTLayoutQuickSearch.init('kt_quick_search');
      }

      if (
        this.extrasNotificationsDisplay &&
        this.extrasNotificationsLayout === 'offcanvas'
      ) {
        // Init Quick Notifications Offcanvas Panel
        KTLayoutQuickNotifications.init('kt_quick_notifications');
      }

      if (
        this.extrasQuickActionsDisplay &&
        this.extrasQuickActionsLayout === 'offcanvas'
      ) {
        // Init Quick Actions Offcanvas Panel
        KTLayoutQuickActions.init('kt_quick_actions');
      }

      if (this.extrasCartDisplay && this.extrasCartLayout === 'offcanvas') {
        // Init Quick Cart Panel
        KTLayoutQuickCartPanel.init('kt_quick_cart');
      }

      if (this.extrasQuickPanelDisplay) {
        // Init Quick Offcanvas Panel
        KTLayoutQuickPanel.init('kt_quick_panel');
      }

      if (this.extrasUserDisplay && this.extrasUserLayout === 'offcanvas') {
        // Init Quick User Panel
        KTLayoutQuickUser.init('kt_quick_user');
      }
    });
  }
  onClick() {
    let abc = document.body.classList;
    if (abc?.contains('aside-minimize')) {
      document.body.classList.remove('aside-minimize');
     let element =  document
       .getElementById('kt_aside_toggle');
      if(element)
        element.classList.remove('aside-toggle-active');
    } else {
      document.body.classList.add('aside-minimize');
    }
  }
}
