<!--begin::Quick Panel-->
<div id="kt_quick_panel"
     class="offcanvas pb-10 notification_offcanvas"
     [ngClass]="extrasQuickPanelOffcanvasDirectionCSSClass">
  <!--begin::Header-->
  <div class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5 flex-column bgi-no-repeat rounded-top"
       style="background-image:url('assets/media/misc/dropdown-header-bg.png')">
    <!--begin::Title-->
    <h3 class="text-white fw-bold px-9 mt-10 mb-6">Notifications
      <span class="fs-8 opacity-75 ps-3">{{notificationService.notificationCount}}</span>
    </h3>
    <!--end::Title-->
    <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-1"
        role="tablist">

      <li class="nav-item">
        <a class="nav-link cursor-pointer nav-link text-white opacity-75 opacity-state-100 pb-4"
           data-toggle="tab"
           [ngClass]="getActiveCSSClasses('kt_quick_panel_notifications')"
           (click)="setActiveTabId('kt_quick_panel_notifications')">Notifications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link cursor-pointer nav-link text-white opacity-75 opacity-state-100 pb-4"
           data-toggle="tab"
           [ngClass]="getActiveCSSClasses('kt_quick_panel_logs')"
           (click)="setActiveTabId('kt_quick_panel_logs')">Alerts</a>
      </li>


      <!-- <li class="nav-item">
        <a class="nav-link cursor-pointer nav-link text-white opacity-75 opacity-state-100 pb-4"
           [ngClass]="getActiveCSSClasses('kt_quick_panel_settings')"
           (click)="setActiveTabId('kt_quick_panel_settings')"
           data-toggle="tab">Logs</a>
      </li> -->
    </ul>
    <!-- <div class="offcanvas-close mt-n1 pr-5">
      <a href="#"
         class="btn btn-xs btn-icon btn-light btn-hover-primary"
         id="kt_quick_panel_close">
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div> -->
  </div>
  <!--end::Header-->

  <!--begin::Content-->
  <div class="offcanvas-content px-10 cs_offcanvas-height">
    <div class="tab-content">
      <!--begin::Tabpane-->
      <div class="tab-pane fade pt-2 pr-5 mr-n5"
           id="kt_quick_panel_notifications"
           [ngClass]="getActiveCSSClasses('kt_quick_panel_notifications')"
           role="tabpanel">
        <ng-container *ngIf="loading; else noLoadingData">
          <span class="spinner__loading"
                style="    display: flex;
          justify-content: center;"
                [ngClass]="'center'">
            <div>
              <mat-spinner align="center"
                           diameter="20"> </mat-spinner>
            </div>
          </span>
        </ng-container>
      </div>
      <!--end::Tabpane-->


      <!--begin::Tabpane-->
      <div class="tab-pane fade pt-2 pr-5 mr-n5"
           id="kt_quick_panel_logs"
           [ngClass]="getActiveCSSClasses('kt_quick_panel_logs')"
           role="tabpanel">
        <!--begin::Nav-->
        <div class="navi navi-icon-circle navi-spacer-x-0">
          <div class="d-flex flex-column px-3">
            <!--begin::Section-->
            <div class="pt-5">
              <!--begin::Title-->
              <h3 class="text-dark text-center fw-bolder">Get Pro Access</h3>
              <!--end::Title-->
              <!--begin::Text-->
              <div class="text-center text-gray-600 fw-bold pt-1">Outlines keep you honest. They stopping you from
                amazing poorly about drive</div>
              <!--end::Text-->
              <!--begin::Action-->
              <div class="text-center mt-5 mb-9">
                <a href="#"
                   class="btn btn-sm btn-primary px-6"
                   data-bs-toggle="modal"
                   data-bs-target="#kt_modal_upgrade_plan">Upgrade</a>
              </div>
            </div>
            <!--end::Action-->

            <!--end::Section-->
            <!--begin::Illustration-->
            <div class="text-center px-4">
              <img class="mw-100 mh-200px"
                   alt="metronic"
                   src="assets/media/illustrations/alert.png">
            </div>
            <!--end::Illustration-->
          </div>
        </div>
        <!--end::Nav-->
      </div>
      <!--end::Tabpane-->

      <!--begin::Tabpane-->
      <div class="tab-pane fade pt-2 pr-5 mr-n5"
           id="kt_quick_panel_settings"
           [ngClass]="getActiveCSSClasses('kt_quick_panel_settings')"
           role="tabpanel">
        <!--begin::Nav-->
        <div class="navi navi-icon-circle navi-spacer-x-0">
          <h6>Settings</h6>
        </div>
        <!--end::Nav-->
      </div>
      <!--end::Tabpane-->


    </div>
  </div>
  <!--end::Content-->
</div>
<!--end::Quick Panel-->
<ng-template #noNewNotifications>
  <!--begin::Nav-->
  <div class="d-flex flex-center text-center text-muted min-h-200px">
    All caught up!
    <br />
    No new notifications.
  </div>
  <!--end::Nav-->
</ng-template>
<ng-template #noLoading>Load more</ng-template>
<ng-template #noLoadingData>
  <ng-container *ngIf="notifications?.data?.length; else noNewNotifications">
    <div class="text-right"> <a href="javascript:;"
         (click)="markAllAsRead()">Clear All</a></div>
    <!--begin::Nav-->
    <div class="navi navi-icon-circle navi-spacer-x-0">
      <ng-container *ngFor="let notification of notifications?.data">
        <!--begin::Item-->
        <a href="javascript:;"
           class="navi-item" (click)="redirect(notification)">
          <div class="navi-link rounded">
            <div class="symbol symbol-50 mr-3">
              <div class="symbol-label"><i class="flaticon-bell text-success icon-lg"></i></div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                {{notification.message}}
              </div>
              <div class="text-muted">
                <span class="mr-3">{{getDate(notification.createdOn) |
                  date}}</span><span>{{getDate(notification.createdOn) |
                  timeAgo
                  }}</span>
              </div>
            </div>
            <div class="navi-icon ml-5">
              <i class="ki ki-close font-size-10 icon-md text-danger"
                 (click)="markAsRead(notification.id)"></i>
            </div>
          </div>
        </a>
        <!--end::Item-->
      </ng-container>
      <!--begin::Action-->
      <div class="text-right py-5">
        <ng-container *ngIf="notifications?.totalCount > notifications?.data?.length"> <a href="javascript:;"
             (click)="loadMoreNotifications()">
            <ng-container *ngIf="nextLoading; else noLoading">
              <span class="spinner__loading"
                    style="    display: flex;
              justify-content: center;"
                    [ngClass]="'center'">
                <div>
                  <mat-spinner align="center"
                               diameter="20"> </mat-spinner>
                </div>
              </span>
            </ng-container>
          </a></ng-container>
      </div>
      <!--end::Action-->
    </div>
    <!--begin::Action-->
    <div class="text-right py-5">
    </div>
    <!--end::Action-->
    <!--end::Nav-->
  </ng-container>
</ng-template>
