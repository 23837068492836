import { HrConfigurationVmInput } from '@generated/queries';
import { Expose } from 'class-transformer';

export class InvoiceConfig {
  businessId: string | undefined = null;
  @Expose() showPurchaseOrder: boolean | undefined = false;
  @Expose() showPurchaseInvoice: boolean | undefined = false;
  @Expose() showSaleInvoice: boolean | undefined = false;
  @Expose() showQuotation: boolean | undefined = false;
}
export class HrConfig implements HrConfigurationVmInput {
  @Expose() loansEntitlement: number;
  @Expose() medicalClaimEntitlement: number;
}
