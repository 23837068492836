export class PageResult<T> {
  data: T[] | undefined;
  pageInfo: PageInfo;
  totalCount: number | 0;
  error: any | undefined;

  constructor(result?: any) {
    if (result) {
      if (result.items?.length) {
        this.totalCount = result.totalCount;
        this.pageInfo = new PageInfo(result.pageInfo);
        this.data = result.nodes ?? result.items;
      } else {
        this.data = [];
      }
    } else {
      this.data = [];
      this.pageInfo = new PageInfo();
    }
  }
}
export class PageInfo {
  hasNextPage: string | undefined;
  hasPreviousPage: string | undefined;
  constructor(info?: any) {
    if (info) {
      this.hasNextPage = info.hasNextPage;
      this.hasPreviousPage = info.hasPreviousPage;
    }
  }
}
