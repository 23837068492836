import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class ValidationService {
  constructor() {}

  getValidationError(control: AbstractControl, label: string, force?: boolean) {
    if (control?.valid && !force) return '';
    if (control?.pristine && !force) return '';

    if (control?.errors?.required) return `${label} is required`;

    if (control?.errors?.email) return `Invalid ${label} format.`;

    if (control?.errors?.pattern) return `${label} is not valid.`;

    if (control?.errors?.invalidPhoneNumber) return `Invalid ${label}.`;

    if (control?.errors?.minlength)
      return `${label} should be at least ${control?.errors.minlength.requiredLength} characters long.`;

    if (control?.errors?.maxlength)
      return `${label} should not be longer than ${control?.errors.maxlength.requiredLength} characters.`;

    if (control?.errors?.min)
      return `${label} should not be less than ${control?.errors.min.min}.`;

    if (control?.errors?.max)
      return `${label} should not be greater than ${control?.errors.max.max}.`;

    if (control?.errors?.notEqual) return control?.errors?.notEqual;

    if (control?.errors?.notMatch) return control?.errors?.notMatch;

    if (control?.errors?.atLeastOne) return control?.errors?.atLeastOne;

    if (control?.errors?.range) return control?.errors?.range;

    if (control?.errors?.greater) return control?.errors?.greater;

    return label && control?.errors ? `Invalid value for ${label}.` : '';
  }
}
