// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@core/services/auth.service';
// RxJS
import { Observable, of } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const url = state.url.split('/');
    const admin =
      this.authService.isHrAdmin() ||
      this.authService.isFinanceAdmin() ||
      this.authService.isAgentAdmin() ||
      this.authService.isTimeTrackingAdmin();

    if (url.includes('settings')) {
        if (!admin) this.router.navigateByUrl('');
        return of(true);
    }

    if (url.includes('hr-setup')) {
      if (!this.authService.isHrManager()) this.router.navigateByUrl('');
    } else if (url.includes('hr')) {
      if (
        url.includes('employees') ||
        url.includes('job-positions') ||
        url.includes('payroll-setup')
      ) {
        if (!this.authService.isHrManager()) this.router.navigateByUrl('');
      } else {
        if (!this.authService.isHrMember()) this.router.navigateByUrl('');
      }
    }
    const currentRoute = url[url.length - 1];

    if (url.includes('projects') || url.includes('timesheets')) {
      if (!this.authService.isTimeTrackingMember())
        this.router.navigateByUrl('');
    }

    if (currentRoute === 'tasks' || url.includes('clients')) {
      if (!this.authService.isTimeTrackingManager())
        this.router.navigateByUrl('');
    }

    if (url.includes('agent') || url.includes('businesses')) {
      if (!this.authService.isAgentManager()) this.router.navigateByUrl('');
    }

    if (url.includes('finance')) {
      if (!this.authService.isFinanceManager()) this.router.navigateByUrl('');
    }

    return of(true);
  }
}
