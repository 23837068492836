// import { KTLayoutQuickUser } from '@themes/metronic/assets/js/layout/extended/quick-user';

import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { User } from '@core/model/user/user';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/profile/services/business.service';
import { Business } from '@core/model/business/business';
import { Softwares } from '@core/utils/Softwares';
import { LayoutService } from '@themes/metronic/app/_metronic/core';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-user-offcanvas',
  templateUrl: './user-offcanvas.component.html',
  styleUrls: ['./user-offcanvas.component.scss'],
})
export class UserOffcanvasComponent implements OnInit {
  agentUrl = Softwares.AgentUrl;
  attendanceUrl = Softwares.AttendanceUrl;
  extrasUserOffcanvasDirection = 'offcanvas-right';
  user$: Observable<User>;
  defaultBusiness: any;
  isAdmin: boolean;
  businesses: Business[] = [];
  pic = null;
  constructor(
    private layout: LayoutService,
    private businessService: BusinessService,
    private router: Router,
    public auth: AuthService
  ) {
    this.auth.user$.subscribe((x) => {
      this.user$ = of(x);
    });
  }

  toggleDisplay() {
    document.body.removeAttribute('data-offcanvas-offcanvas');
    const getId = document.getElementById('kt_quick_user');
    getId.classList.remove('offcanvas-on');
    getId.nextSibling.remove();
    let getGro = document.getElementById('kt_quick_user_toggle');
    function handleClick() {
      getGro.click();
    }
    handleClick();
  }
  isAllowed() {
    return (
      this.auth.isOwner() || this.auth.isHrAdmin() || this.auth.isHrManager()
    );
  }
  ngOnInit(): void {
    this.extrasUserOffcanvasDirection = `offcanvas-${this.layout.getProp(
      'extras.user.offcanvas.direction'
    )}`;
    this.auth.user$.subscribe((x) => {
      this.user$ = of(x);
    });
    this.businessService
      .getMyActiveBusinesses()
      .subscribe((x) => (this.businesses = x));
    this.auth.business$.subscribe((selectedBusiness) => {
      this.defaultBusiness = selectedBusiness;
      this.isAdmin =
        this.auth.isAgentAdmin() ||
        this.auth.isTimeTrackingAdmin() ||
        this.auth.isHrAdmin() ||
        this.auth.isFinanceAdmin();
    });
  }
  goToProfile() {
    this.router.navigate(['./profile']);
  }

  isActive(id: string) {
    return id === this.defaultBusiness.id;
  }
  async setAsDefault(business: Business) {
    if (business.id === this.auth.businessId) return;
    this.auth.business$.next(business);
    localStorage.setItem('businessName', business.name);
    localStorage.setItem('switchTenant', 'TRUE');
    window.location.reload();
  }
  logout() {
    this.auth.logout();
  }
}
