import { Routes } from '@angular/router';
import { PermissionGuard } from '@core/guards/permission.guard';
import { AuthGuardAuthenticadeOnly } from '@core/services/auth-guard-authenticated-only.service';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { LayoutComponent } from './layout/layout.component';
export const AppRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardAuthenticadeOnly],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuardAuthenticadeOnly],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'businesses',
        loadChildren: () =>
          import('./businesses/business.module').then((x) => x.BusinessModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'finance',
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./finance/finance.module').then((m) => m.FinanceModule),
      },
      {
        path: 'hr',
        canActivate: [PermissionGuard],
        loadChildren: () => import('./hr/hr.module').then((m) => m.HrModule),
      },
      {
        path: 'businesses',
        canActivate: [AuthGuardAuthenticadeOnly],
        loadChildren: () =>
          import('./businesses/business.module').then((m) => m.BusinessModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'time-tracking',
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./time-tracking/time-tracking.module').then(
            (m) => m.TimeTrackingModule
          ),
      },
      {
        path: 'agent',
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./agent/agent.module').then((m) => m.AgentModule),
      },
      {
        path: 'template',
        loadChildren: () =>
          import('./template/template.module').then((m) => m.TemplateModule),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
