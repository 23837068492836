import { AppInterceptorService } from './core/services/app-interceptor.service';

import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Layout2Module } from './layout/layout.module';
import { LayoutComponent } from './layout/layout.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RtlService } from '@core/services/rtl.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { NgxLoadingModule } from 'ngx-loading';
import { CoreModule } from '@core/core.module';
import { PagesModule } from './pages/pages.module';
import { MatMenuModule } from '@angular/material/menu';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgDialogAnimationService } from "ng-dialog-animation";
import { FileService } from '@shared/services/file.service';
import { ValidationService } from '@shared/services/validation.service';
import { NgSelectModule } from '@ng-select/ng-select';
// import { DecimalProvider } from '@rxweb/reactive-form-validators';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [AppComponent, NotFoundComponent, LayoutComponent],
  imports: [
    CoreModule,
    CommonModule,
    BrowserModule,
    PagesModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    Layout2Module,
    MatMenuModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgSelectModule,
    MatTooltipModule,
    RxReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxLoadingModule.forRoot({
      primaryColour: '#0d4a8f',
      secondaryColour: '#0d4a8f',
      tertiaryColour: '#0d4a8f',
    }),
  ],
  providers: [TranslateService, RtlService, NgDialogAnimationService, FileService, ValidationService],
  bootstrap: [AppComponent],
})

@Injectable({
  providedIn: 'root'
})
export class AppModule {}
