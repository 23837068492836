import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';

export class ObjectUtils {
  static isValueNull(val: any): boolean {
    return !val || val === '' || val === undefined || val === null;
  }

  static getParsedValue(val: any): any {
    return this.isValueNull(val) ? null : val;
  }

  static isObjEmpty(val: any): boolean {
    return this.isValueNull(val) || Object.keys(val).length === 0;
  }
  static isPrimitive(test) {
    return test !== Object(test);
  }
  static removeNullValues(val: any) {
    Object.keys(val).forEach((key) => {
      if (!this.isPrimitive(val[key])) this.removeNullValues(val[key]);
      this.isValueNull(val[key]) && delete val[key];
    });
  }
  static clearCache(cache: any, systemObject: string) {
    if (!ObjectUtils.isValueNull(systemObject)) {
      Object.keys(cache?.data?.data).forEach((key) => {
        if (key.toLowerCase().includes(systemObject.toLowerCase())) {
          cache?.data?.delete(key);
        }
      });
    }
  }
  static toCamelCase(text: string) {
    return text.substr(0, 1).toLowerCase() + text.substr(1);
  }
  static addQueryParamsToCurrentRoute(
    queryParams,
    router: Router,
    activatedRoute: ActivatedRoute
  ) {
    this.removeNullValues(queryParams);
    router.navigate([], {
      relativeTo: activatedRoute,
      queryParams,
    });
  }
}
export function strictMap<T, V>(cls: ClassType<T>, plain: V[]): T[];
export function strictMap<T, V>(cls: ClassType<T>, plain: V): T;
export function strictMap<T, V>(dest: ClassType<T>, src: V | V[]): T | T[] {
  return plainToClass(dest, src, { excludeExtraneousValues: true });
}

export function getDateFormat(date: Date): any {
  date = new Date(date);
  return {
    day: date.getUTCDate(),
    month: date.getUTCMonth() + 1,
    year: date.getUTCFullYear(),
  };
}
