import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorModel } from './model/error-model';
export interface IToastButton {
  id: string;
  title: string;
};
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

  @Input() public error:ErrorModel
  @Input() public gqlError:any;
  @Output() closeError=new EventEmitter<boolean>();
  close() {
  this.error=undefined;
  this.closeError.emit(true);
}
}
