<ng-container *ngIf="asideSecondaryDisplay && asideSelfMinimizeToggle">
  <!--begin::Aside Toggle-->
  <span class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
        id="kt_aside_toggle_custom"
        (click)="onClick()"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Toggle Aside">
    <i class="ki ki-bold-arrow-back icon-sm"></i>
  </span>
  <!--end::Aside Toggle-->
</ng-container>

<ng-container *ngIf="extraSearchDisplay">
  <ng-container *ngIf="extrasSearchLayout === 'offcanvas'">
    <!--begin::Search-->
    <a class="btn btn-icon btn-clean btn-lg mb-1"
       id="kt_quick_search_toggle"
       data-toggle="tooltip"
       data-placement="right"
       data-container="body"
       data-boundary="window"
       title="Quick Search">
      <span [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
    </a>

    <!--end::Search-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasNotificationsDisplay">
  <ng-container *ngIf="extrasNotificationsLayout === 'offcanvas'">
    <!--begin::Notifications-->
    <a class="btn btn-icon btn-clean btn-lg mb-1"
       id="kt_quick_notifications_toggle">
      <span [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
      <span class="pulse-ring"></span>
    </a>
    <!--end::Notifications-->
  </ng-container>
</ng-container>
<!--begin::Quick Actions-->
<!-- <ng-container *ngIf="extrasQuickActionsDisplay">
  <ng-container *ngIf="extrasQuickActionsLayout === 'offcanvas'">

    <a class="btn btn-icon btn-clean btn-lg mb-1 position-relative"
       id="kt_quick_actions_toggle">
      <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
    </a>

  </ng-container>
</ng-container> -->
<!--end::Quick Actions-->

<ng-container *ngIf="extrasCartDisplay">
  <ng-container *ngIf="extrasCartLayout === 'offcanvas'">
    <!--begin::Cart-->
    <a class="btn btn-icon btn-clean btn-lg mb-1"
       id="kt_quick_cart_toggle">
      <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'"
            cacheSVG="true"
            class="svg-icon svg-icon-xl"></span>
    </a>
    <!--end::Cart-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasQuickPanelDisplay">
  <!--begin::Quick panel-->
  <a class="btn btn-icon btn-clean btn-lg mb-1 position-relative"
     id="kt_quick_panel_toggle">
    <span [inlineSVG]="'./assets/media/svg/icons/notifications.svg'"
          cacheSVG="true"
          class="svg-icon svg-icon-xl"></span>
    <span *ngIf="notificationService.notificationCount > 0"
          class="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">{{notificationService.notificationCount}}</span>
  </a>
  <!--end::Quick panel-->
</ng-container>

<ng-container *ngIf="extrasLanguagesDisplay">
  <app-language-selector style="margin-top: 10px"></app-language-selector>
</ng-container>

<ng-container *ngIf="extrasUserDisplay">
  <ng-container *ngIf="extrasUserLayout === 'offcanvas'">
    <ng-container>
      <!--begin::User-->
      <a class="btn btn-icon btn-clean btn-lg w-40px h-40px"
         id="kt_quick_user_toggle">
        <span class="symbol symbol-30 symbol-lg-40">
          <span [inlineSVG]="'./assets/media/svg/icons/General/User.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-xl"></span>
        </span>
      </a>
      <!--end::User-->
    </ng-container>
  </ng-container>

  <ng-container *ngIf="extrasUserLayout === 'dropdown'">
    <!--begin::User-->
    <ng-container *ngIf="user$ | async as _user">
      <div class="dropdown"
           ngbDropdown
           placement="bottom-right">
        <div class="topbar-item"
             data-toggle="dropdown"
             data-offset="0px,0px"
             ngbDropdownToggle>
          <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ _user.fullName
              }}</span>
            <span class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">{{
                _user.fullName | firstLetter
                }}</span>
            </span>
          </div>
        </div>
        <!--end::Toggle-->
        <!--begin::Dropdown-->
        <div ngbDropdownMenu
             class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <app-user-dropdown-inner></app-user-dropdown-inner>
        </div>
      </div>
      <!--end::Dropdown-->
    </ng-container>
    <!--end::User-->
  </ng-container>
</ng-container>

<!--end::Topbar-->
