<!-- begin::User Panel-->
<div id="kt_quick_user"
     class="offcanvas custom-offcanvas rounded"
     [ngClass]="extrasUserOffcanvasDirection">
  <ng-container *ngIf="user$ | async as _user">
    <!--begin::Header-->
    <!-- <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
      <h3 class="font-weight-bold m-0">
        User Profile
      </h3>
      <a href="#"
         class="btn btn-xs btn-icon btn-light btn-hover-primary"
         id="kt_quick_user_close">
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div> -->
    <!--end::Header-->

    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3 py-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px mr-5">
          <div class="symbol-label"
               [style]="'background-image:url('+(_user?.pic ? _user?.pic : '/assets/media/users/blank.png')+')'">
          </div>

          <i class="symbol-badge bg-success"></i>
        </div>
        <!--end::Avatar-->
        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ _user.firstName }} {{ _user.lastName }}

          </div>
          <a href="javascript:;"
             class="fw-bold text-muted-8  fs-7">
            {{_user.email}}
          </a>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->




    <!--begin::Menu separator-->
    <div class="separator separator-dark my-3 d-block mb-5 border-bottom"></div>
    <!--end::Menu separator-->

    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5">

      <div class="menu-item px-5">
        <a href="javascript:;"
           (click)="goToProfile(); toggleDisplay()"
           class="font-size-13 fw-bold menu-link px-5 text-dark  offcanvas-">My Profile</a>
      </div>

      <div class="menu-item px-5">
        <a routerLink="businesses"
           class="font-size-13 fw-bold menu-link px-5 text-dark "
           (click)="toggleDisplay()">
           Business Profile</a>
      </div>

      <div class="cs_userDropdown menu-item px-5">
        <a href="javascript:;"
           class="font-size-13 fw-bold menu-link px-5 text-dark  position-relative">Switch Account
          <span class="label label-lg text-info label-inline ml-2 bgcs-danger">{{businesses?.length}}</span>
          <i class="font-size-9 menu-arrow position-absolute right-0"></i></a>

        <div class="cs_userDropdownContent">

          <!--start::select business-->
          <div [ngClass]="{'cs-Scrollbar' : businesses?.length > 3 }"
               id="scroll-style">


            <!--Business List-->
            <div class="navi navi-spacer-x-0 p-0">
              <a *ngFor="let item of businesses let i=index"
                 [ngClass]="{'bg-light-primary active-business' : isActive(item.id)}"
                 class="navi-item cursor-pointer"
                 href="javascript:;"
                 (click)="!isActive(item.id) && setAsDefault(item); toggleDisplay()">
                <div class="navi-link rounded pl-5">

                  <div class="navi-text">
                    <div class="font-weight-bold">{{item.name}} </div>

                  </div>
                  <div class="navi-icon d-flex">
                    <i *ngIf="isActive(item.id)"
                       class=" font-size-12 fa fa-check text-primary mr-3"></i>

                  </div>

                </div>
              </a>
            </div>

            <!--End Business List-->

          </div>
          <!--end::select business-->

        </div>
      </div>

      <div class="cs_userDropdown menu-item px-5"
           *ngIf="isAllowed()">
        <a href="javascript:;"
           class="font-size-13 fw-bold menu-link px-5 text-dark  position-relative"
           (click)="toggleDisplay()">Download
          <i class="font-size-9 menu-arrow position-absolute right-0"></i></a>

        <div class="cs_userDropdownContent">
          <div class="menu-item px-5">
            <a [href]="agentUrl"
               target="_blank"
               class="font-size-13 fw-bold menu-link px-5 text-dark "
               (click)="toggleDisplay()">Agent</a>
          </div>
          <div class="menu-item px-5">
            <a [href]="attendanceUrl"
               target="_blank"
               class="font-size-13 fw-bold menu-link px-5 text-dark "
               (click)="toggleDisplay()">Attendance</a>
          </div>
        </div>
      </div>

      <div class="menu-item px-5">
        <a routerLink="profile/change-password"
           class="font-size-13 fw-bold menu-link px-5 text-dark "
           (click)="toggleDisplay()">Change Password</a>
      </div>

      <div class="separator separator-dark my-3 d-block border-bottom"></div>

      <div class="menu-item px-5">
        <a href="javascript:;"
           (click)="logout()"
           class="font-size-13 fw-bold menu-link px-5 text-dark ">Sign Out</a>
      </div>
      <!--end::Header-->
    </div>
    <!-- end::User Panel-->