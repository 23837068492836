import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
export const authConfig: AuthConfig = getObj();
 function getObj(): AuthConfig {
  const config: AuthConfig = {
    issuer: environment.IssuerUri,
    clientId: 'BrickclayPortal',
    requireHttps: environment.RequireHttps,
    redirectUri: environment.Uri + '/login-callback',
    scope: 'openid profile email bk_api',
    responseType: 'code',
    postLogoutRedirectUri: window.location.origin,
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: 5000, // For faster testing
    timeoutFactor: 0.25, // For faster testing
    sessionChecksEnabled: true,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  };
  setTenant(config);
  return config;
}
function setTenant(config: AuthConfig) {
  const tenant = localStorage.getItem('businessName');
  if (tenant) {
    config.customQueryParams = {
      acr_values: `tenant:${tenant}`,
    };
  }
}
