export interface MessageParams {
  crud?: UserAction.Crud;
  object?: SystemObject;
  type?: string;
  message?: string;
}

export enum UserAction {
  crud = 'crud',
  other = 'other',
}

export namespace UserAction {
  export enum Crud {
    get = 'load',
    create = 'create',
    update = 'update',
    delete = 'remove',
    archive = 'archive',
    restore = 'restore',
    hired = 'hire',
    terminated = 'terminate',
    assigned = 'assigned',
    sync = 'sync',
    enable = 'enabled',
    disable = 'disabled',
  }

  export enum Other {
    refund = 'refund',
    update = 'update',
    delete = 'remove',
  }
}
export enum NotifyTitle {
  get = 'load',
  create = 'new',
  update = 'modified',
  delete = 'removed',
}
export namespace Message {
  export enum Crud {
    success = '{_object_} {_action_}d successfully.',
    error = 'Failed to {_action_} {_object_}.',
  }

  export enum Other {
    success = '{_object_} {_action_}d successfully.',
    error = 'Failed to {_action_} {_object_}.',
  }
}
export enum SystemObject {
  profile = 'Profile',
  user = 'User',
  role = 'Role',
  account = 'Account',
  business = 'Business',
  journalVoucher = 'Journal Voucher',
  cashVoucher = 'Cash Voucher',
  cashReceiveVoucher = 'Cash Receive Voucher',
  cashPaymentVoucher = 'Cash Payment Voucher',
  department = 'Department',
  interviewCategory = 'Interview Category',
  interviewCriterion = 'Interview Criterion',
  jobFunction = 'Job Function',
  jobTitle = 'Job Title',
  leaveType = 'Leave Type',
  leave = 'Leave Request',
  loanType = 'Loan Type',
  loan = 'Loan Request',
  medicalClaim = 'Medical Claim',
  reimbursement='Reimbursement',
  bonusCategory = 'Bonus Category',
  bonusType = 'Bonus Type',
  certification = 'Certification',
  institute = 'Institute',
  hiringReason = 'Hiring Reason',
  terminationReason = 'Termination Reason',
  allowanceCategory = 'Allowance Category',
  allowanceType = 'Allowance Type',
  employee = 'Employee',
  salary = 'Salary',
  invitation = 'Invitation',
  timesheet = 'Time Sheet',
  financialYear = 'Financial Year',
  product = 'Product',
  client = 'Client',
  project = 'Project',
  projectTask = 'Project Task',
  subTask = 'Sub Task',
  genericTask = 'Generic Task',
  purchaseOrder = 'Purchase Order',
  purchaseInvoice = 'Purchase Invoice',
  saleInvoice = 'Sale Invoice',
  quotation = 'Quotation',
  attendanceRecord = 'Attendance Records',
  password = 'Password',
  agent = 'Agent',
  agentConfiguration = 'Agent Configuration',
  attendanceConfiguration = 'Attendance Configuration',
  invoiceCustomization = 'Invoice Customization',
  hrConfig = 'HR Configuration',
  businessEmail = 'Business Email',
  systemAccount = 'System Account',
  tax = 'Tax',
  app = 'App',
  ticketCategory = 'Ticket Category',
  ticket = 'Ticket',
  ticketComment = 'Ticket Comment',
  notes = 'Note',
  location = 'Location',
  facility = 'Facility',
  area = 'Area',
  problem = 'Problem',
  status = 'Status',
  priority = 'Priority',
  noteType = 'Note Type',
}
