<!-- splash screen -->
<div #splashScreen
     class="kt-splash-screen text-center">
  <span>Loading user data...</span>

  <!-- <mat-spinner diameter="40"></mat-spinner> -->
  <div class="dot-pulse"></div>
  <!-- <img class="brickclay-logo"
		 [attr.src]="'./assets/media/logos/brickclay.svg'"
		 alt="Product By Brickclay"> -->

  <div style="  bottom: 0;  position: fixed;">
    <p>&copy;{{ settingsService.app.year }} - {{ settingsService.app.name }}</p>
    <p>{{ settingsService.app.description }}</p>
  </div>
</div>