import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-connection-error',
  templateUrl: './connection-error.component.html',
  styleUrls: ['./connection-error.component.scss']
})
export class ConnectionErrorComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConnectionErrorComponent>) { }

  ngOnInit() {
  }

}
