import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'bk-resume-viewer',
  template: `
  <div id="overlay-container"
  (window:keydown.esc)="close()"
  class="overlay_screenshots">

<a href="javascript:void(0)"
  class="closebtn"
  (click)="close()">&times;</a>
  <iframe
  [src]="path"
  frameborder="0"
 ></iframe>
</div>
  
  `,
  styleUrls: ['./resume-viewer.component.scss']
})
export class ResumeViewerComponent {
  @Input() path: SafeResourceUrl;
  constructor(public dialogRef: MatDialogRef<ResumeViewerComponent>) { }
  close() {
    this.dialogRef.close();

  }

}
