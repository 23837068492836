import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NotifyService } from './services/notify.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthModule } from './auth/auth.module';
@NgModule({
  declarations: [],
  imports: [MatDialogModule, AuthModule.forRoot(), CommonModule],
  providers: [NotifyService, TitleCasePipe],
  exports: [TitleCasePipe],
})
export class CoreModule {}
