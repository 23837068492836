import { AttendanceNotifications } from './../../core/model/business/business';
import { GetSessionBusinessesGQL, AttendanceNotificationVmInput } from './../../../generated/queries';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { plainToClass } from 'class-transformer';
import {
  Business,
  CreateBusiness,
  UpdateBusiness,
} from '@core/model/business/business';
import {
  GetBusinessEmailsGQL,
  GetBusinessTypesGQL,
  GetIndustriesGQL,
  UpdateBusinessEmailsGQL,
} from '@generated/queries';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@shared/services/base.service';
import { BusinessType } from '@core/model/business-type/business-type';
import { Industry } from '@core/model/industry/industry';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { ObjectUtils, strictMap } from '@core/utils/object-utils';
import {
  ArchiveBusinessDocument,
  ArchiveBusinessMutation,
  CreateBusinessDocument,
  CreateBusinessMutation,
  GetMyActiveBusinessesDocument,
  GetMyActiveBusinessesQuery,
  GetMyBusinessByIdDocument,
  GetMyBusinessByIdQuery,
  GetMyBusinessesDocument,
  GetMyBusinessesQuery,
  RestoreBusinessDocument,
  RestoreBusinessMutation,
  UpdateBusinessDocument,
  UpdateBusinessMutation,
} from '@generated/auth';
import { LocalStorageDataService } from '@core/services/local-storage.service';

@Injectable()
export class BusinessService {
  business: Business[] = [];
  routUrl: string;
  private apollo: ApolloBase;
  constructor(
    private businessTypes: GetBusinessTypesGQL,
    private industries: GetIndustriesGQL,
    baseService: BaseService,
    private apolloProvider: Apollo,
    private sessionBusinesses: GetSessionBusinessesGQL,
    private businessEmails: GetBusinessEmailsGQL,
    private updateEmails: UpdateBusinessEmailsGQL,
    private localStorageService: LocalStorageDataService
  ) {
    this.routUrl = baseService.getBaseUrl();
    this.apollo = this.apolloProvider.use('authClient');
  }

  getBusiness(id: string, fetchPolicy?: any): Observable<Business> {
    return this.apollo
      .query<GetMyBusinessByIdQuery>({
        query: GetMyBusinessByIdDocument,
        variables: {
          id,
        },
        fetchPolicy:
          fetchPolicy ?? this.localStorageService.getFetchPolicy('business'),
      })
      .pipe(
        map((x) =>
          strictMap(
            Business,
            x?.data?.session?.businesses.associatedBusinesses[0]
          )
        )
      );
  }

  getMyBusinesses(): Observable<Business[]> {
    return this.apollo
      .query<GetMyBusinessesQuery>({
        query: GetMyBusinessesDocument,
        fetchPolicy: this.localStorageService.getFetchPolicy('business'),
      })
      .pipe(
        map((x) =>
          plainToClass(
            Business,
            x?.data?.session.businesses.associatedBusinesses
          )
        )
      );
  }
  getMySessionBusinesses(): Promise<any[]> {
    return this.sessionBusinesses
      .fetch(
        {},
        { fetchPolicy: this.localStorageService.getFetchPolicy('business') }
      )
      .pipe(map((x) => x?.data?.session.businesses))
      .toPromise();
  }
  getMyActiveBusinesses(): Observable<Business[]> {
    return this.apollo
      .query<GetMyActiveBusinessesQuery>({
        query: GetMyActiveBusinessesDocument,
        fetchPolicy: this.localStorageService.getFetchPolicy('business'),
      })
      .pipe(
        map((x) =>
          plainToClass(
            Business,
            x?.data?.session.businesses.associatedBusinesses
          )
        )
      );
  }

  getBusinessTypes(): Observable<BusinessType[]> {
    return this.businessTypes.fetch().pipe(
      map((x) => {
        return plainToClass(BusinessType, x?.data?.businessTypes);
      })
    );
  }

  getIndustries(): Observable<Industry[]> {
    return this.industries.fetch().pipe(
      map((x) => {
        return plainToClass(Industry, x?.data?.industries);
      })
    );
  }

  getBusinessEmails(): Promise<AttendanceNotifications> {
    return this.businessEmails
      .fetch(
        {},
        { fetchPolicy: this.localStorageService.getFetchPolicy('business') }
      )
      .pipe(
        map((x) => {
          return plainToClass(AttendanceNotifications, x?.data?.session.business.config.attendanceNotification);
        })
      )
      .toPromise();
  }

  updateBusinessEmails(data: AttendanceNotifications): Promise<string> {
    return this.updateEmails
      .mutate({ vm: strictMap(AttendanceNotifications, data) })
      .pipe(map((x) => x.data.business.updateEmails))
      .toPromise();
  }

  add(business: Business): Promise<void> {
    return this.apollo
      .mutate<CreateBusinessMutation>({
        mutation: CreateBusinessDocument,
        variables: {
          model: strictMap(CreateBusiness, business),
        },
        update: (cache) => ObjectUtils.clearCache(cache, 'business'),
      })
      .pipe(map(() => {}))
      .toPromise();
  }

  update(business: UpdateBusiness): Promise<void> {
    return this.apollo
      .mutate<UpdateBusinessMutation>({
        mutation: UpdateBusinessDocument,
        variables: {
          model: business,
        },
        update: (cache) => ObjectUtils.clearCache(cache, 'business'),
      })
      .pipe(map(() => {}))
      .toPromise();
  }

  archive(businessId: string): Promise<void> {
    return this.apollo
      .mutate<ArchiveBusinessMutation>({
        mutation: ArchiveBusinessDocument,
        variables: {
          businessId,
        },
        update: (cache) => ObjectUtils.clearCache(cache, 'business'),
      })
      .pipe(map(() => {}))
      .toPromise();
  }

  restore(businessId: string): Promise<void> {
    return this.apollo
      .mutate<RestoreBusinessMutation>({
        mutation: RestoreBusinessDocument,
        variables: {
          businessId,
        },
        update: (cache) => ObjectUtils.clearCache(cache, 'business'),
      })
      .pipe(map(() => {}))
      .toPromise();
  }
}
