import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthGuardAuthenticadeOnly } from '@core/services/auth-guard-authenticated-only.service';
import { AuthGuardOnlyAdmin } from '@core/services/auth-guard-only-admin.service';
import { AuthService } from '@core/services/auth.service';
import { SettingsService } from '@core/services/settings.service';
import { VersionService } from '@shared/services/version.service';
import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { authConfig } from './auth-config';
import { authProdConfig } from './auth-config.prod';
import { authQaConfig } from './auth-config.qa';
import { authModuleConfig } from './auth-module-config';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()],
  providers: [
    SettingsService,
    VersionService,
    AuthService,
    AuthGuardOnlyAdmin,
    AuthGuardAuthenticadeOnly,
  ],
})
export class AuthModule {

  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AuthConfig,
          useValue: this.getAuthConfigValue(),
        },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }


  static getAuthConfigValue(): AuthConfig {
    if (environment.qa) {
      return authQaConfig;
    } else if (environment.production) {
      return authProdConfig;
    } else {
      return authConfig;
    }
  }
}
