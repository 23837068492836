import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoCommaPipe } from './no-comma.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { MinuteSecondsPipe } from './minutes-to-hms.pipe';
import { CapitalizePipePipe } from './capitalize-pipe.pipe';
import { ChunkPipe } from './chunk.pipe';
@NgModule({
  imports: [CommonModule],
  declarations: [
    NoCommaPipe,
    DateFormatPipe,
    NumberToWordsPipe,
    TimeAgoPipe,
    MinuteSecondsPipe,
    CapitalizePipePipe,
    ChunkPipe,
  ],
  exports: [
    NoCommaPipe,
    DateFormatPipe,
    NumberToWordsPipe,
    TimeAgoPipe,
    MinuteSecondsPipe,
    CapitalizePipePipe,
    ChunkPipe,
  ],
})
export class PipeModule {}
