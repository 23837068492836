<div class="d-flex flex-column flex-root">
  <!--begin::Error-->
  <div style="background: #babcbe url(/assets/media/bg/ErrorBackground.png) top left repeat;">
    <span class="text-white">Connection Error</span>


    <!--Connection Error Modal-->
    <div class="modal fade show"
         id="connectionError"
         tabindex="-1"
         role="dialog"
         aria-labelledby="connectionErrorLabel"
         aria-hidden="true"
         style="display: block;">
      <div class="modal-dialog modal-dialog-centered"
           role="document">
        <div class="modal-content rounded-0"
             style="max-width: 450px;">
          <div class="modal-body">
            <div class="coonection-icon">
              <svg viewBox="0 0 9 9"
                   height="20px"
                   width="20px"
                   role="presentation"
                   focusable="false"
                   xmlns:svg="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink">
                <g>
                  <path fill="#fff"
                        d="M8.267 8H.733c-.6 0-.916-.623-.62-1.129L2.014 3.53 3.896.384c.302-.507.903-.514 1.197-.008L7.001 3.65l1.882 3.229C9.183 7.383 8.881 8 8.267 8z">
                  </path>
                  <circle fill="#ffba01"
                          cx="4.5"
                          cy="6.178"
                          r=".615"></circle>
                  <path fill="#ffba01"
                        d="M4.623 2.428H3.98l.164 2.85h.774l.165-2.85z"></path>
                </g>
              </svg>
            </div>
            <h2>Connection error</h2>
            <p>
              We had trouble reaching the server. This is usually an indication that the network is down or a firewall
              or browser extension
              (such as an ad blocker) is mistakenly preventing access. Your experience may be degraded.
            </p>
            <div class="d-flex justify-content-end mt-2">
              <button type="button"
                      class="btn btn-secondary btn-sm cs-btn-sm btn-square mr-2"
                      (click)="dialogRef.close()">Ignore</button>
              <a routerLink="/"
                 (click)="dialogRef.close()"
                 class="btn btn-success btn-sm cs-btn-sm btn-square">Refresh</a>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--begin::Content-->


  </div>
</div>
