import { NgModule } from '@angular/core';
// import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
// import { InMemoryCache } from 'apollo-cache-inmemory';
import { InMemoryCache } from '@apollo/client/cache';
// import { ApolloLink, concat, split } from 'apollo-link';
import { ApolloLink, ApolloClientOptions, split } from '@apollo/client/core';
// import { ApolloClientOptions } from 'apollo-client';
import { environment } from 'src/environments/environment';
import {
  APOLLO_NAMED_OPTIONS,
  APOLLO_OPTIONS,
  NamedOptions,
} from 'apollo-angular';
// import { createHttpLink } from 'apollo-link-http';
import { createHttpLink } from '@apollo/client/link/http';
// import { WebSocketLink } from 'apollo-link-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getOperationDefinition } from '@apollo/client/utilities';
import { ObjectUtils } from '@core/utils/object-utils';
import { HttpClientModule } from '@angular/common/http';
import { HttpLink, HttpLinkModule} from 'apollo-angular-link-http';
// import { setContext } from 'apollo-link-context';
import { setContext } from '@apollo/client/link/context';

const uri = `${environment.apiUrl}/query`; // <-- add the URL of the GraphQL server here
export function createApollo(): ApolloClientOptions<any> {
  // WebSocket
  const wsClient = new WebSocketLink({
    uri: `${environment.wsUrl}/query`,
    options: {
      reconnect: true,
    },
  });
  let token = localStorage.getItem('access_token');
  // Todo debug token is missing

  let link = ApolloLink.from([
    createPersistedQueryLink({
      sha256(args: any): string | PromiseLike<string> {
        return undefined;
      }, useGETForHashedQueries: true}),
    createHttpLink({ uri }),
  ]);
  const cache = new InMemoryCache();
  link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getOperationDefinition(query);
      if (operation === 'query') {
        const systemObjects: string[] = JSON.parse(
          localStorage.getItem('systemObjects')
        );

        if (systemObjects?.length) {
          systemObjects.map((systemObject) =>
            ObjectUtils.clearCache(cache, systemObject)
          );
          localStorage.setItem('systemObjects', JSON.stringify([]));
        }
      }
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsClient,
    link
  );

  return {
    link: authLink.concat(link),
    cache: cache,
    defaultOptions: { query: { fetchPolicy: 'cache-first' } },
  };
}
export function createAuthApollo(): NamedOptions {
  const uri = `${environment.authUrl}/graphql`;
  const link = ApolloLink.from([
    createHttpLink({ uri }),
  ]);

  return {
    authClient: {
      cache: new InMemoryCache(),
      link: authLink.concat(link),
    },
  } as any;
}

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('access_token');
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    }
  }
});
@NgModule({
  exports: [HttpClientModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createAuthApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
