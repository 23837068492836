import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RtlService {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  load(): Promise<void> {
    return new Promise((res) => {
      const lang = localStorage.getItem('lang');
      try {
        const dir = lang ? JSON.parse(lang).dir : 'ltr';
        this.loadDirStyle(dir).then(res);
      } catch (error) {
        this.loadDirStyle('ltr').then(res);
      }
    });
  }
  loadDirStyle(dir: string) {
    return new Promise<void>((res) => {
      var css: Promise<any>;
      if (dir === 'rtl') {
        css = import(
          /* webpackChunkName: "[metronic-style-rtl]" */
          '../../../assets/sass/style.angular.rtl.js'
        );
      } else {
        css = import(
          /* webpackChunkName: "[metronic-style]" */
          '../../../assets/sass/style.angular.ltr.js'
        );
      }
      css
        .then((s) => s.default)
        .then((x) => {
          this.appendCss(x);
          this.setHeaderDirection(dir);
          res();
        });
    });
  }
  appendCss(cssFile: string) {
    const headEl = this.document.getElementsByTagName('head')[0];
    const existingCssLink = this.document.getElementById(
      'metronic-theme'
    ) as HTMLLinkElement;

    if (existingCssLink) {
      existingCssLink.href;
    } else {
      const newLinkEl = this.document.createElement('style');
      newLinkEl.id = 'metronic-theme';
      newLinkEl.textContent = cssFile;
      // newLinkEl.rel = 'stylesheet';
      // newLinkEl.href = cssFile;
      headEl.appendChild(newLinkEl);
    }
  }
  setHeaderDirection(dir: string) {
    if (
      dir == 'ltr' &&
      this.document.getElementsByTagName('html')[0].hasAttribute('dir')
    ) {
      const html = this.document.getElementsByTagName('html')[0];
      html.removeAttribute('dir');
      html.removeAttribute('direction');
      html.style.direction = null;
    } else if (
      dir === 'rtl' &&
      !this.document.getElementsByTagName('html')[0].hasAttribute('dir')
    ) {
      const html = this.document.getElementsByTagName('html')[0];
      html.setAttribute('dir', 'rtl');
      html.setAttribute('direction', 'rtl');
      html.style.direction = 'rtl';
    }
  }
}
