import {
  Observable,
  throwError as _observableThrow,
  of as _observableOf,
} from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  setToken(token: string) {
    throw new Error('Method not implemented.');
  }
  private apiUrl: string;
  private authUrl: string;
  public isDebug: boolean;
  token: string;
  constructor() {
    this.apiUrl = environment.apiUrl;
    this.authUrl = environment.authUrl;

    // this.token =
    //   localStorage.getItem('token') || sessionStorage.getItem('token');
  }
  getBaseUrl(arg: string = ''): string {
    return this.apiUrl;
  }
  getAuthUrl(arg: string = ''): string {
    return this.authUrl;
  }
  transformOptions(options): Promise<any> {
    return new Promise((res, rej) => {
      const token = this.token;
      if (token) {
        let headers: HttpHeaders = options.headers;
        headers = headers.append('Authorization', 'Bearer ' + token);
        options.headers = headers;
      }
      res(options);
    });
  }
}

export class SwaggerException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isSwaggerException = true;

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true;
  }
}
