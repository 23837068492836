import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Business } from '@core/model/business/business';
import { User } from '@core/model/user/user';
import { ConnectionErrorComponent } from '@shared/components/connection-error/connection-error.component';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import jwt_decode from 'jwt-decode';
import { filter, map } from 'rxjs/operators';
import { GetInvoiceConfigurationGQL } from '@generated/queries';
import { InvoiceConfig } from '@core/model/business-config/business-config';
import { strictMap } from '@core/utils/object-utils';
import { AppPolicies } from '@core/app.config';
import { MyProfileService } from 'src/app/profile/services/profile.service';
import { environment } from 'src/environments/environment';
import { Apollo } from 'apollo-angular';
import { authConfig } from 'src/app/core/auth/auth-config'

@Injectable({ providedIn: 'root' })
export class AuthService {
  private loading = true;
  defaultImage = '/assets/media/users/blank.png';
  isAdmin: any;
  myEmail: any;
  //clientIds: string[] = [];
  public get clientIds(): string[] {
    const accessToken = this.oauthService.getAccessToken();

    const claims = jwt_decode(accessToken) as any;

    return claims?.client_ids?.length > 0 ? (claims.client_ids as string).split(",") : [];

  }
  id: any;
  myName: any;
  roles: string[] = [];
  userPermissionsSubject: BehaviorSubject<string[]>;
  public user$: ReplaySubject<User> = new ReplaySubject<User>(1);
  public business$: ReplaySubject<Business> = new ReplaySubject<Business>(1);
  private _businessId: string;
  private _businessName: string;
  public accessTokenObj = {};
  public get businessId(): string {
    if (!this._businessId)
      this._businessId = localStorage.getItem('businessId');

    return this._businessId;
  }
  public set businessId(v: string) {
    localStorage.setItem('businessId', v);
    this._businessId = v;
  }
  public get businessName(): string {
    if (!this._businessName)
      this._businessName = localStorage.getItem('businessName');

    return this._businessName;
  }
  public set businessName(v: string) {
    localStorage.setItem('businessName', v);
    this._businessName = v;
  }
  accountId: string = '';
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/login');
  }

  get userPermissionsValue(): string[] {
    return this.userPermissionsSubject.value;
  }

  constructor(
    private oauthService: OAuthService,
    private apollo: Apollo,
    private router: Router,
    private dialog: MatDialog,
    private invoiceConfig: GetInvoiceConfigurationGQL,
    private profileService: MyProfileService
  ) {
    this.canActivateProtectedRoutes$.subscribe(async (x) => {
      await this.syncDecodedToken();
    });
    // Useful for debugging:
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        //ignore
      } else {
        //ignore

      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe((e) => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(
        filter((e) => [
          'session_terminated',
          'session_error',
          'token_expired',
          'token_error',
          'discovery_document_load_error',
          'code_error',
          //'silent_refresh_error',
          'invalid_grant',
          //'token_validation_error',
          'login_required'
        ].includes(e.type))
      )
      .subscribe((e) => {
        console.log('oauthService: ' + e.type);
        this.navigateToLoginPage()
      });

    this.userPermissionsSubject = new BehaviorSubject<string[]>([]);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  hasPermission(permission: string) {
    return this.userPermissionsValue.indexOf(permission) > -1;
  }

  async syncDecodedToken() {
    const accessToken = this.oauthService.getAccessToken();

    const claims = jwt_decode(accessToken) as any;

    this.accessTokenObj = accessToken;
    this.businessId = claims?.tid;
    this.businessName = claims?.tname;

    this.id = claims?.sub;
    this.roles =
      claims?.role?.constructor === Array ? claims?.role : [claims?.role];
    const invoiceConfig = await this.invoiceConfig
      .fetch()
      .pipe(
        map((x) =>
          strictMap(
            InvoiceConfig,
            x?.data?.session?.business?.config?.invoiceConfig
          )
        )
      )
      .toPromise();
    this.business$.next(<Business>{
      id: claims?.tid,
      name: claims?.tname,
      invoiceConfiguration: invoiceConfig,
    });
    const profile = await this.profileService.getProfile();
    this.user$.next(<User>{
      id: claims?.sub,
      firstName: profile.firstName,
      lastName: profile.lastName,
      fullName: profile.fullName ?? profile.firstName + ' ' + profile.lastName,
      email: profile.email,
      pic: profile.pic ?? this.defaultImage,
      city: profile.city,
      province: profile.province,
      zipCode: profile.zipCode,
      country: profile.country,
      dateOfBirth: profile.dateOfBirth,
      displayName: profile.displayName,
      isExternal: claims?.idp !== 'local' ? true : false,
    });

    this.userPermissionsSubject.next(claims?.permissions as string[]);

    this.user$.subscribe((x) => {
      this.myEmail = x.email;
      this.myName = x.fullName;
    });
    if (this.businessName) {
      localStorage.setItem('businessName', this.businessName);
    }
  }
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  getLoader() {
    return this.loading;
  }

  async connectionError(msg: string) {
    let ref = this.dialog.open(ConnectionErrorComponent);
    let instance = ref.componentInstance;
    //instance.confirmationText = msg;
    await ref.afterClosed().toPromise();
  }
  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        .then(
          () => new Promise((resolve) => setTimeout(() => resolve(true), 1000))
        )

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())

        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }

          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          return this.oauthService
            .silentRefresh(authConfig)
            .then(() => Promise.resolve())
            .catch((result) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction = [
                'interaction_required',
                'login_required',
                'account_selection_required',
                'consent_required',
              ];

              if (
                result &&
                result.reason &&
                errorResponsesRequiringUserInteraction.indexOf(
                  result.reason.error
                ) >= 0
              ) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                // this.oauthService.initImplicitFlow();
                //
                // Instead, we'll now do this:

                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })
        .then(() => {
          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            this.router.navigateByUrl(stateUrl);
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }

  public login(targetUrl?: string) {
    //alert(targetUrl);
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.apollo.client.resetStore();
    this.oauthService.logOut();
    localStorage.clear();
  }
  public refresh() {
    this.oauthService.silentRefresh(authConfig);
  }
  async switchBusiness() {
    this.navigateToLoginPage();
    await this.oauthService.setupAutomaticSilentRefresh();
    await this.syncDecodedToken().catch(() => {
      this.navigateToLoginPage();
    });

  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }

  isOwner() {
    return this.roles.some((x) => AppPolicies.Owner.split(',').includes(x));
  }

  isAgentManager(): boolean {
    return this.roles.some((x) =>
      AppPolicies.TrackingAgentManager.split(',').includes(x)
    );
  }

  isTimeTrackingManager(): boolean {
    return this.roles.some((x) =>
      AppPolicies.TimeTrackingManager.split(',').includes(x)
    );
  }
  isHrManager(): boolean {
    return this.roles.some((x) => AppPolicies.HrManager.split(',').includes(x));
  }
  isFinanceManager(): boolean {
    return this.roles.some((x) =>
      AppPolicies.FinanceManager.split(',').includes(x)
    );
  }

  isAgentAdmin(): boolean {
    return this.roles.some((x) =>
      AppPolicies.TrackingAgentAdmin.split(',').includes(x)
    );
  }

  isTimeTrackingAdmin(): boolean {
    return this.roles.some((x) =>
      AppPolicies.TimeTrackingAdmin.split(',').includes(x)
    );
  }
  isHrAdmin(): boolean {
    return this.roles.some((x) => AppPolicies.HrAdmin.split(',').includes(x));
  }
  isFinanceAdmin(): boolean {
    return this.roles.some((x) =>
      AppPolicies.FinanceAdmin.split(',').includes(x)
    );
  }

  isTimeTrackingMember(): boolean {
    return this.roles.some((x) =>
      AppPolicies.TimeTrackingMember.split(',').includes(x)
    );
  }
  isHrMember(): boolean {
    return this.roles.some((x) => AppPolicies.HrMember);
  }
  isFinanceMember(): boolean {
    return this.roles.some((x) =>
      AppPolicies.FinanceMember.split(',').includes(x)
    );
  }
  containsPermission(item: any): boolean {
    if (item?.permissions?.length) {
      let permissions = item.permissions.join(',');
      permissions = permissions.split(',');
      let containsPermission = permissions.some((x) => this.roles.includes(x));

      if (item.title.includes('Orders') || item.title.includes('Invoices')) {
        if (containsPermission)
          containsPermission = item.submenu.some(
            (x) => this.containsInvoiceConfiguration(x.title, false) === true
          );
      }
      if (item?.page) {
        if (item?.page?.includes('invoices'))
          containsPermission = this.containsInvoiceConfiguration(
            item.title,
            containsPermission
          );
      }
      return containsPermission;
    } else {
      return true;
    }
  }

  containsInvoiceConfiguration(title: string, containsPermission: boolean) {
    switch (title) {
      case 'Purchase Orders':
        this.business$.subscribe((x) => {
          containsPermission = x?.invoiceConfiguration?.showPurchaseOrder;
        });
        break;
      case 'Purchase Invoices':
        this.business$.subscribe(
          (x) =>
            (containsPermission = x?.invoiceConfiguration?.showPurchaseInvoice)
        );
        break;
      case 'Quotations':
        this.business$.subscribe(
          (x) => (containsPermission = x?.invoiceConfiguration?.showQuotation)
        );
        break;
      default:
        this.business$.subscribe(
          (x) => (containsPermission = x?.invoiceConfiguration?.showSaleInvoice)
        );
        break;
    }

    return containsPermission;
  }
}
