import { Injectable } from '@angular/core';
import { ObjectUtils, strictMap } from '@core/utils/object-utils';
import {
  GetLatestNotificationGQL,
  GetNotificationsGQL,
  MarkAllAsReadGQL,
  MarkAsReadGQL,
  NotificationTypes,
} from '@generated/queries';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationModel } from '@core/model/notification';
import { AuthService } from './auth.service';
import { PageResult } from '@core/model/pagination/page-result';
import { LocalStorageDataService } from './local-storage.service';

@Injectable()
export class NotificationService {
  private notifications$: BehaviorSubject<
    NotificationModel
  > = new BehaviorSubject<NotificationModel>(null);
  notificationCount = 0;
  constructor(
    private notifications: GetNotificationsGQL,
    private markAsReadMutation: MarkAsReadGQL,
    private markAllAsReadMutation: MarkAllAsReadGQL,
    private subscription: GetLatestNotificationGQL,
    private authService: AuthService,
    private localStorageService: LocalStorageDataService
  ) {
    this.subscription
      .subscribe({
        businessId: this.authService.businessId,
        sessionId: this.authService.id,
      })
      .subscribe((notification: any) => {
        notification = strictMap(
          NotificationModel,
          notification?.data?.showLatestNotification
        );
        if (notification) {
          notification?.type === NotificationTypes.Command
            ? this.setSystemObject(notification)
            : this.setNotification$(notification);
        }
      });
  }

  fetchNotifications(skip?: number) {
    return this.notifications
      .fetch({ skip }, { fetchPolicy: 'network-only' })
      .pipe(
        tap(
          (x) =>
            (this.notificationCount = x.data.session.notifications.totalCount)
        ),
        map(
          (x) => new PageResult<NotificationModel>(x.data.session.notifications)
        )
      )
      .toPromise();
  }
  getNotifications$() {
    return this.notifications$.asObservable();
  }

  setNotification$(notification: NotificationModel) {
    this.notifications$.next(notification);
  }

  markAsRead(notificationId: string): Promise<string> {
    return this.markAsReadMutation
      .mutate({ notificationId })
      .pipe(map((x) => x.data.business.notifications.markAsRead))
      .toPromise();
  }

  markAllAsRead(ids: string[]): Promise<string> {
    return this.markAllAsReadMutation
      .mutate({ ids })
      .pipe(map((x) => x.data.business.notifications.markAllAsRead))
      .toPromise();
  }

  setSystemObject(notification: NotificationModel) {
    const camelCase = ObjectUtils.toCamelCase(notification?.systemObject);
    if (!ObjectUtils.isValueNull(camelCase))
      this.localStorageService.addToSystemObjects(camelCase);
  }
}
