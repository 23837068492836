<div *ngIf="error"
     class="app-error-width mx-auto">
  <!--begin::Content-->
  <div class="text-center p-10">

    <div class="d-flex flex-column justify-content-center">
      <label class="mr-3">
        <ng-container *ngTemplateOutlet="ErrorIcon"></ng-container>
      </label>
    </div>
    <p class="mb-0 mr-2 text-dark line-15 mt-2 font-size-12"
       *ngIf="error?.title"> {{error?.title}} </p>
    <p class="text-dark line-height-md mb-3 font-siz-10"
       *ngIf="error?.Description"> {{error?.Description}}
    </p>

    <div class="d-flex justify-content-between">
      <a href="javascript:;"
         class="text-dark">Need Help?</a>
      <a href="javascript:;"
         (click)="close()"
         class="text-dark">Close</a>

    </div>
    <div class="my-2"></div>

    <div class="card custom-card">
      <div class="card-body  text-left">
        <div class="card-title">
          <div class="d-flex align-items-center">
            <h2 class="mb-0 mr-3">Summary</h2>
            <bk-svg-summery></bk-svg-summery>
          </div>
        </div>
        <div *ngIf="gqlError">
          {{gqlError}}
        </div>
        <div class="row"
             *ngIf="!gqlError">
          <div class="col-sm-6">
            <div class="d-flex flex-column mb-5">
              <label class="text-grey mb-0">Trace Id</label>
              <label class="text-dark-100"
                     *ngIf="error?.TraceId"> {{error?.TraceId}}</label>
            </div>
            <div class="d-flex flex-column mb-5">
              <label class="text-danger mb-0">Error Code</label>
              <label class="text-dark-100"
                     *ngIf="error?.Code"> {{error?.Code}}</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="d-flex flex-column mb-5">
              <label class="text-grey mb-0">Content</label>
              <label class="text-dark-100"
                     *ngIf="error?.Message"> {{error?.Message}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!--end::Content-->
</div>
<ng-template #ErrorIcon>
  <svg _ngcontent-hxc-c12=""
       _ngcontent-nti-c12=""
       viewBox="0 0 113.386 113.773"
       height="100px"
       width="100px"
       role="presentation"
       focusable="false"
       xmlns:svg="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       id="FxSymbol0-03e"
       data-type="370"
       class="ng-star-inserted">
    <g _ngcontent-hxc-c12=""
       _ngcontent-nti-c12="">
      <path _ngcontent-hxc-c12=""
            _ngcontent-nti-c12=""
            d="M55.5 98.284c-.049 0-.092.013-.142.014a8.2 8.2 0 0 0-13.45-8.24 10.761 10.761 0 0 0-21.1 1.825 6.287 6.287 0 0 0-.27-.041 8.181 8.181 0 0 0-13.309 3.411 5.582 5.582 0 0 0-7.421 5.283 5.662 5.662 0 0 0 5.6 5.674H18.5a7.267 7.267 0 0 0 1.064 0H55.5a3.962 3.962 0 1 0 .007-7.923H55.5z"
            class="msportalfx-svg-c15"
            style="fill: #8b8bea; border: none !important;"></path>
      <path _ngcontent-hxc-c12=""
            _ngcontent-nti-c12=""
            d="M55.5 98.284c-.049 0-.092.013-.142.014a8.2 8.2 0 0 0-13.45-8.24 10.761 10.761 0 0 0-21.1 1.825 6.287 6.287 0 0 0-.27-.041 8.181 8.181 0 0 0-13.309 3.411 5.582 5.582 0 0 0-7.421 5.283 5.662 5.662 0 0 0 5.6 5.674H18.5a7.267 7.267 0 0 0 1.064 0H55.5a3.962 3.962 0 1 0 .007-7.923H55.5z"
            fill="#aab3bd"
            style="fill: #3699FF;border: none !important;"></path>
      <path _ngcontent-hxc-c12=""
            _ngcontent-nti-c12=""
            d="M94.676 48.235a35.58 35.58 0 1 0 0 27.694 35.581 35.581 0 0 0 0-27.694zM76.823 83.656a25.115 25.115 0 0 1-6.665 3.337 26.559 26.559 0 0 1-8.258 1.319 25.389 25.389 0 0 1-10.207-2.079A26.446 26.446 0 0 1 37.75 72.289a25.384 25.384 0 0 1-2.074-10.207 26.583 26.583 0 0 1 1.318-8.257 25.154 25.154 0 0 1 3.333-6.666zm11.3-21.574a26.621 26.621 0 0 1-1.317 8.257 25.13 25.13 0 0 1-3.335 6.666l-36.5-36.5a25.151 25.151 0 0 1 6.667-3.335 26.579 26.579 0 0 1 8.262-1.312 25.381 25.381 0 0 1 10.207 2.074 26.44 26.44 0 0 1 13.944 13.943 25.4 25.4 0 0 1 2.074 10.207z"
            fill="#aab3bd"
            style="fill: #e44b53; border: none !important;"></path>
      <path _ngcontent-hxc-c12=""
            _ngcontent-nti-c12=""
            d="M54.429 25.244c-.05 0-.095.013-.145.014a8.427 8.427 0 0 0-13.83-8.477 11.065 11.065 0 0 0-21.7 1.877 7.422 7.422 0 1 0-1.281 14.732h36.956a4.074 4.074 0 0 0 0-8.148z"
            opacity=".8"
            class="msportalfx-svg-c15"
            style="fill: #8b8bea; border: none !important;"></path>
      <path _ngcontent-hxc-c12=""
            _ngcontent-nti-c12=""
            d="M113.193 71.366a2.593 2.593 0 0 0-2.582-2.6 2.787 2.787 0 0 0-.341.021 6.945 6.945 0 0 0-13.283-4.044 4.746 4.746 0 1 0-1.554 9.233H110.8v-.011a2.6 2.6 0 0 0 2.395-2.595z"
            class="msportalfx-svg-c15"
            style="fill: #8b8bea; border: none !important;"></path>
      <path _ngcontent-hxc-c12=""
            _ngcontent-nti-c12=""
            d="M54.429 25.244c-.05 0-.095.013-.145.014a8.427 8.427 0 0 0-13.83-8.477 11.065 11.065 0 0 0-21.7 1.877 7.422 7.422 0 1 0-1.281 14.732h36.956a4.074 4.074 0 0 0 0-8.148zm58.764 46.122a2.593 2.593 0 0 0-2.582-2.6 2.787 2.787 0 0 0-.341.021 6.945 6.945 0 0 0-13.283-4.044 4.746 4.746 0 1 0-1.554 9.233H110.8v-.011a2.6 2.6 0 0 0 2.395-2.595z"
            fill="#aab3bd"
            style="fill: #3699FF;border: none !important;"></path>
    </g>
  </svg>
</ng-template>