import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { LayoutService } from '@themes/metronic/app/_metronic/core';
import { KTUtil } from '@themes/metronic/assets/js/components/util';

import { BodyModule } from '@progress/kendo-angular-grid';
import { SubMenuService } from '../services/sub-menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { AppPolicies } from '@core/app.config';
import { AsideDynamicComponent } from './aside-dynamic/aside-dynamic.component';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit, OnDestroy {
  TABS: string[] = [
    'kt_aside_tab_0',
    'kt_aside_tab_1',
    'kt_aside_tab_2',
    'kt_aside_tab_3',
    'kt_aside_tab_4',
    'kt_aside_tab_5',
    'kt_aside_tab_6',
  ];
  activeTabId;
  disableAsideSelfDisplay: boolean;
  asideMenuStatic: true;
  disableAsideSecondaryDisplay: boolean;
  ulCSSClasses: string;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = true;
  currentUrl: string;
  subscriptions: Subscription[] = [];

  financeManager: AppPolicies = AppPolicies.FinanceManager;
  hrMember: AppPolicies = AppPolicies.HrMember;
  timeMember: AppPolicies = AppPolicies.TimeTrackingMember;
  agentManager: AppPolicies = AppPolicies.TrackingAgentManager;
  constructor(
    private layout: LayoutService,
    private router: Router,
    private submenu: SubMenuService,
    private cdr: ChangeDetectorRef,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.activeTabId = this.TABS[0];
    // load view settings
    this.disableAsideSelfDisplay = this.layout.getProp('aside.self.display') === false;
    this.asideMenuStatic = this.layout.getProp('aside.menu.static');
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown')
      ? '1'
      : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${
      this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''
    }`;
    this.disableAsideSecondaryDisplay = this.layout.getProp(
      'aside.secondary.display'
    );
  }
  GoToDashboard() {
    this.activeTabId = this.TABS[0];
    this.router.navigate(['dashboard']);
    this.closeAside();

  }
  setTab(id) {
    this.activeTabId = id;
    const asideWorkspace = KTUtil.find(
      document.getElementById('kt_aside'),
      '.aside-secondary .aside-workspace'
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
    this.cdr.detectChanges();
  }
  isMenuItemActive(path) {
    if (!this.currentUrl || !path) {
      return false;
    }

    if (this.currentUrl === path) {
      return true;
    }

    if (this.currentUrl.indexOf(path) > -1) {
      return true;
    }

    return false;
  }
  closeMe(id, modulePath) {
    if (modulePath?.length) this.setSelectedMenu(modulePath);
    this.setTab(id);

    let abc = document.body.classList;
    if (abc?.contains('aside-minimize')) {
      document.body.classList.remove('aside-minimize');
      const element = document
        .getElementById('kt_aside_toggle');
        if(element)
          element.classList.remove('aside-toggle-active');
    }
  }
  goToLink(url: string) {
    this.router.navigate([url]);
  }
  setSelectedMenu(url: string) {
    this.currentUrl = url;
    this.submenu.changeItem(url);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
  closeAside() {
    let abc = document.body.classList;
    if (!abc?.contains('aside-minimize')) {
      document.body.classList.add('aside-minimize');
      const element = document
        .getElementById('kt_aside_toggle');
        if(element)
          element.classList.add('aside-toggle-active');
    }
  }
  hasAccess(right?: string) {
    if (right) {
      let permissions = right.split(',');
        let containsPermission = permissions.some((x) => this.authService.hasPermission(x));
      return containsPermission;
      //return this.authService.hasPermission(right) ? true : false;
    }
    return false;
  }
}
