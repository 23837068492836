import { SalaryAccounts } from './salary-accounts';
import { Expose } from 'class-transformer';
import { AgentConfig } from '../agent-config/agent-config';
import { ThirdLevelAccount } from '../accounts/thirdLevelAccount.model';
// import { Address } from './address';
import { Contact } from './contact';
import {
  CreateBusinessVmInput,
  Industries,
  States,
  TenantTypes,
  UpdateBusinessVmInput,
} from '@generated/auth';
import { InvoiceConfig } from '../business-config/business-config';
import { AttendanceNotificationVmInput } from '@generated/queries';

export class Business {
  @Expose() id?: string;
  @Expose() ownerId?: string;
  // @Expose() addresses?: Address[] | undefined;
  @Expose() contacts?: Contact[] | undefined;
  @Expose() state?: States | undefined;
  @Expose() businessTypeId?: TenantTypes;
  @Expose() industryId?: Industries;
  @Expose() name?: string | undefined;
  @Expose() country?: string | undefined;
  @Expose() currency?: string | undefined;
  @Expose() email?: string | undefined;
  @Expose() phone?: string | undefined;
  @Expose() fax?: string | undefined;
  @Expose() mobile?: string | undefined;
  @Expose() tollFree?: string | undefined;
  @Expose() website?: string | undefined;
  @Expose() logo?: string | undefined;

  @Expose() agentConfigurationId?: string | undefined;
  @Expose() agentConfigurations: AgentConfig | undefined;
  @Expose() salaryAccounts: SalaryAccounts | undefined;
  @Expose() zipCode: string | undefined;
  @Expose() timeZone: string | undefined;
  @Expose() addressLine1: string | undefined;
  @Expose() addressLine2: string | undefined;
  @Expose() city: string | undefined;
  @Expose() province: string | undefined;
  @Expose() isOwned: boolean | undefined;
  @Expose() invoiceConfiguration: InvoiceConfig | undefined;
}
export class CreateBusiness implements CreateBusinessVmInput {
  ownerId: any;
  @Expose() name: string;
  @Expose() businessTypeId: any;
  @Expose() country: string;
  @Expose() currency: string;
  @Expose() industryId: any;
}
export class UpdateBusiness implements UpdateBusinessVmInput {
  @Expose() currency: string;
  @Expose() country: string;
  ownerId: any;
  @Expose() id: any;
  @Expose() name: string;
  @Expose() phone: string;
  @Expose() mobile?: string;
  @Expose() fax?: string;
  @Expose() tollFree?: string;
  @Expose() website?: string;
  @Expose() zipCode: string | undefined;
  @Expose() timeZone: string | undefined;
  @Expose() addressLine1: string | undefined;
  @Expose() addressLine2: string | undefined;
  @Expose() city: string | undefined;
  @Expose() province: string | undefined;
  logo?: string;
}

export class AttendanceNotifications implements AttendanceNotificationVmInput {
  @Expose() enable: boolean | false;
  @Expose() time: string | '';
  @Expose() emails: string[] | undefined;
}
