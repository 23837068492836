import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { UpdatePassword, UpdateUserProfile, User } from '@core/model/user/user';
import {
  CloseAccountDocument,
  CloseAccountMutation,
  ForgotPasswordDocument,
  ForgotPasswordMutation,
  GetMyProfileDocument,
  GetMyProfileQuery,
  UpdateMyProfileDocument,
  UpdateMyProfileMutation,
  UpdatePasswordDocument,
  UpdatePasswordMutation,
} from '@generated/auth';
import { ObjectUtils, strictMap } from '@core/utils/object-utils';
import { Apollo, ApolloBase } from 'apollo-angular';
import { BaseService } from '@shared/services/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MyProfileService {
  authUrl: string;
  private apollo: ApolloBase;
  constructor(
    private apolloProvider: Apollo,
    private baseService: BaseService,
    private http: HttpClient
  ) {
    this.apollo = this.apolloProvider.use('authClient');
    this.authUrl = this.baseService.getAuthUrl();
  }
  getProfile(): Promise<User> {
    return this.apollo
      .query<GetMyProfileQuery>({
        query: GetMyProfileDocument,
      })
      .pipe(map((x) => strictMap(User, x.data.session.me)))
      .toPromise();
  }
  update(model: User): Promise<void> {
    return this.apollo
      .mutate<UpdateMyProfileMutation>({
        mutation: UpdateMyProfileDocument,
        variables: { model: strictMap(UpdateUserProfile, model) },
        update: (cache) => ObjectUtils.clearCache(cache, 'profile'),
      })
      .pipe(map(() => {}))
      .toPromise();
  }
  updatePhoto(fileName: string, file: Blob): Promise<string> {
    const routUrl = `${this.authUrl}/me/picture`;

    const formData = new FormData();
    formData.append('file', file, fileName);

    return this.http
      .post<string>(routUrl, formData)
      .pipe(map((x) => x))
      .toPromise();
  }
  deletePhoto(): Promise<void> {
    const routUrl = `${this.authUrl}/me/picture`;
    return this.http.delete<void>(routUrl).toPromise();
  }
  close() {
    return this.apollo
      .mutate<CloseAccountMutation>({
        mutation: CloseAccountDocument,
      })
      .toPromise();
  }
  forgotPassword(email: string) {
    return this.apollo
      .mutate<ForgotPasswordMutation>({
        mutation: ForgotPasswordDocument,
        variables: { email },
      })
      .toPromise();
  }
  updatePassword(model: UpdatePassword): Promise<any> {
    return this.apollo
      .mutate<UpdatePasswordMutation>({
        mutation: UpdatePasswordDocument,
        variables: { model: strictMap(UpdatePassword, model) },
      })
      .toPromise();
  }
}
