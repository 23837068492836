import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationModel } from '@core/model/notification';
import { PageResult } from '@core/model/pagination/page-result';
import { NotificationService } from '@core/services/notification.service';
import { NotifyService } from '@core/services/notify.service';
import { NotificationTypes } from '@generated/queries';
import { LayoutService } from '@themes/metronic/app/_metronic/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {AppUtilService} from "@shared/services/app-util.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-quick-panel-offcanvas',
  templateUrl: './quick-panel-offcanvas.component.html',
  styleUrls: ['./quick-panel-offcanvas.component.scss'],
})
export class QuickPanelOffcanvasComponent implements OnInit, OnDestroy {
  notifications: PageResult<NotificationModel> = new PageResult<
    NotificationModel
  >();
  loading: boolean;
  nextLoading: boolean;
  unsubscribe$: Subject<boolean> = new Subject();
  constructor(
    private layout: LayoutService,
    public notificationService: NotificationService,
    private notifyService: NotifyService,
    private appUtilService: AppUtilService,
    private router: Router
  ) {
    this.getNotifications();
  }
  extrasQuickPanelOffcanvasDirectionCSSClass = 'offcanvas-right';
  activeTabId:
    | 'kt_quick_panel_logs'
    | 'kt_quick_panel_notifications'
    | 'kt_quick_panel_settings' = 'kt_quick_panel_logs';

  ngOnInit(): void {
    this.extrasQuickPanelOffcanvasDirectionCSSClass = `offcanvas-${this.layout.getProp(
      'extras.quickPanel.offcanvas.direction'
    )}`;
    this.setActiveTabId('kt_quick_panel_notifications');
    this.notificationService
      .getNotifications$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (notification) => {
        if (
          !this.notifications?.data.find((x) => x.id === notification?.id) &&
          notification?.type === NotificationTypes.Alert &&
          !notification.isRead
        ) {
          this.notifyService.info(notification);
        }
        await this.getNotifications();
      });
  }

  setActiveTabId(tabId) {
    this.activeTabId = tabId;
  }

  getActiveCSSClasses(tabId) {
    if (tabId !== this.activeTabId) {
      return '';
    }
    return 'active show';
  }

  getDate(date: Date) {
    var localDateTime = new Date(date);
    localDateTime.setMinutes(
      localDateTime.getMinutes() - localDateTime.getTimezoneOffset()
    );
    return localDateTime;
  }

  async getNotifications() {
    this.loading = true;
    this.notifications = await this.notificationService.fetchNotifications(
      null
    );
    this.loading = false;
    this.notificationService.notificationCount = this.notifications?.data?.length;
  }

  async loadMoreNotifications() {
    this.nextLoading = true;
    const notifications = await this.notificationService.fetchNotifications(
      this.notifications?.data?.length
    );
    if (notifications?.data?.length) {
      notifications?.data?.forEach((notification) => {
        if (
          !this.notifications?.data.find((x) => x.id === notification?.id) &&
          notification?.type === NotificationTypes.Alert &&
          !notification.isRead
        ) {
          this.notifications.data.push(notification);
        }
      });
      this.notifications.pageInfo = notifications.pageInfo;
    }
    this.nextLoading = false;
    this.notificationService.notificationCount = this.notifications?.data?.length;
  }
  async markAsRead(id: string) {
    const result = await this.notificationService.markAsRead(id);
    if (result) {
      this.notifications.data = this.notifications.data.filter(
        (x) => x.id !== id
      );
      this.notifications.totalCount -= 1;
      this.notificationService.notificationCount = this.notifications?.data?.length;
    }
  }
  async markAllAsRead() {
    this.loading = true;
    const result = await this.notificationService.markAllAsRead(
      this.notifications.data.map((x) => x.id)
    );
    if (result) {
      await this.getNotifications();
    }
    this.loading = false;
  }
  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  redirect(notification: NotificationModel){
    this.router.navigateByUrl(this.appUtilService.getObjectUrl(notification));
  }
}
