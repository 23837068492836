import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardAuthenticadeOnly implements CanActivate {
  private isAuthenticated: boolean;

  constructor(private authService: AuthService) {
    this.authService.isAuthenticated$.subscribe(
      (i) => (this.isAuthenticated = i)
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    if (!environment.dev) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    return this.authService.isDoneLoading$
      .pipe(filter((isDone) => isDone))
      .pipe(
        tap((_) => this.isAuthenticated || this.authService.login(state.url))
      )
      .pipe(map((_) => this.isAuthenticated));
  }
}
