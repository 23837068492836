import { Notification, NotificationTypes } from '@generated/queries';
import { Expose } from 'class-transformer';

export class NotificationModel implements Notification {
  @Expose() id: string | undefined;
  @Expose() isRead: boolean | undefined;
  @Expose() message: string | undefined;
  @Expose() createdOn: Date | undefined;
  @Expose() type: NotificationTypes | undefined;
  @Expose() systemObject: string | undefined;
  @Expose() systemObjectId: string | undefined;
}
