import { EventEmitter } from 'events';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
export class AppConfig {
  constructor() {
    if (!environment.production) {
      this.uploadUrl = 'http://localhost:5001';
    } else {
      this.uploadUrl = '';
      this.generateUrl = '';
    }
    this.isDebug = environment.production ? false : true;
  }
  public uploadUrl: string;
  public generateUrl: string;
  public isDebug: boolean;
  public contentLocation: string;
  public campDomain: string;
  public eventService: EventEmitter = new EventEmitter();
  public init<T>(ctor: new () => T, props: Partial<T>): T {
    return Object.assign(new ctor(), props);
  }
  // public getLatest<T>(subject: ReplaySubject<T>): T {
  //   let value: T;
  //   subject.subscribe(x => (value = x));
  //   subject.unsubscribe();
  //   if (value === undefined) {
  //     throw new Error(`Observable of ${typeof value} must have initial value`);
  //   }
  //   return value;
  // }
  public getRandomColor(str: string, alpha: number): string {
    if (str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        // tslint:disable-next-line:no-bitwise
        const value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return this.hexToHSLA(color, alpha);
    }
  }
  public hexToHSLA(H, alpha) {
    // Convert hex to RGB first
    // tslint:disable-next-line:one-variable-per-declaration
    let r: any, g: any, b: any;
    if (H.length === 4) {
      r = '0x' + H[1] + H[1];
      g = '0x' + H[2] + H[2];
      b = '0x' + H[3] + H[3];
    } else if (H.length === 7) {
      r = '0x' + H[1] + H[2];
      g = '0x' + H[3] + H[4];
      b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    // tslint:disable-next-line:one-variable-per-declaration
    const cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin;
    // tslint:disable-next-line:one-variable-per-declaration
    let h = 0,
      s = 0,
      l = 0;

    // tslint:disable-next-line:max-line-length
    if (delta === 0) {
      h = 0;
    } else if (cmax === r) {
      h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
      h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return 'hsl(' + h + ',' + s + '%,' + l + '%,' + alpha + ')';
  }
  getCurrentValue<R>(obs: ReplaySubject<R>): R {
    let value;
    obs.forEach((x) => (value = x));
    return value;
  }
  deepcopy<T>(o: T): T {
    return JSON.parse(JSON.stringify(o));
  }
  createNarray(dimensions) {
    // tslint:disable-next-line:one-variable-per-declaration
    let t;
    let i = 0;
    const s = dimensions[0];
    const arr = new Array(s);
    if (dimensions.length < 3) {
      for (t = dimensions[1]; i < s; ) {
        arr[i++] = new Array(t);
      }
    } else {
      for (t = dimensions.slice(1); i < s; ) {
        arr[i++] = this.createNarray(t);
      }
    }
    return arr;
  }
  public parseError(err): string {
    if (err.response) {
      const json = this.isJsonString(err.response);
      if (json) {
        return json.Description;
      }
    }
    return err;
  }
  public isJsonString(str): boolean | any {
    try {
      const json = JSON.parse(str);
      return json;
    } catch (e) {
      return false;
    }
  }
  public transformPic(user: any) {
    let str = '';
    if (user.pic) {
      str = `media/users/${user.id}/${user.pic}`;
    } else {
      if (user.firstName) {
        str = str + user.firstName[0].toUpperCase();
      }
      if (user.lastName) {
        str = str + user.lastName[0].toUpperCase();
      }
    }
    return str;
  }
}
export enum AppPolicies {
  Owner = 'owner',
  TimeTrackingAdmin = 'tt:admin,owner',
  TimeTrackingManager = 'tt:manager,tt:admin,owner',
  TimeTrackingMember = 'tt:manager,tt:admin,tt:member,tt:project_client,owner',
  HrAdmin = 'hr:admin,owner',
  HrManager = 'hr:manager,hr:admin,owner',
  HrMember = 'hr:manager,hr:admin,hr:member,owner',
  FinanceAdmin = 'finance:admin,owner',
  FinanceManager = 'finance:manager,finance:admin,owner',
  FinanceMember = 'finance:member,finance:manager,finance:admin,owner',
  TrackingAgentAdmin = 'ta:admin,owner',
  TrackingAgentManager = 'ta:manager,ta:admin,owner',
  TrackingAgentMember = 'ta:manager,ta:admin,ta:member,owner',
}
export enum Roles {
  hr = 'HR',
  finance = 'Finance',
  ta = 'Agent',
  tt = 'Time Tracking',
}
export enum AppPermissions {
  SupportTicketRead = 'read:supportticket',
  SupportTicketWrite = 'write:supportticket',
  //
  SupportTicketSetupRead = 'read:ticketsetup',
  SupportTicketSetupWrite = 'write:ticketsetup',
  //
  LocationRead = 'read:site',
  LocationWrite = 'write:site',
  //
  FacilityRead = 'read:site',
  FacilityWrite = 'write:site',
  //
  AreaRead = 'read:site',
  AreaWrite = 'write:site',
  //
  TicketRead = 'read:ticket',
  TicketWrite = 'write:ticket',
  //
  PriorityRead = 'read:priority',
  PriorityWrite = 'write:priority',
  //
  ProblemRead = 'read:problem',
  ProblemWrite = 'write:problem',
  //
  NoteTypeRead = 'read:notetype',
  NoteTypeWrite = 'write:notetype',
  //
  TicketStatusRead = 'read:ticketstatus',
  TicketStatusWrite = 'write:ticketstatus',
  //
}




