<div class="d-flex flex-column flex-root login-root">
    <!--begin::Login-->
    <div class="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
         id="kt_login">
        <!--begin::Aside-->

        <!--begin::Aside-->
        <!--begin::Content-->
        <div class="content order-1 order-lg-2 d-flex flex-column w-100 pb-0"
             style="background-color: #B1DCED;">
            <!--begin::Title-->
            <div
                 class="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
                <h3 class="display4 font-weight-bolder my-7 text-dark"
                    style="color: #986923;">Amazing Quick Suite</h3>
                <p class="font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70">User Experience &amp;
                    Interface Design, Product Strategy
                    <br>Web Application SaaS Solutions
                </p>
            </div>
            <!--end::Title-->
            <div class="text-center pt-2">
                <button id="kt_login_signin_submit"
                        (click)="login()"
                        class="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3">Sign In</button>
            </div>
            <!--begin::Image-->
            <div class="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                 style="height: 550px;background-image: url(assets/media/svg/illustrations/login-visual-2.svg);">
            </div>
            <div class="p-3 text-center">
                <span>&copy;</span>
                <span>{{ settingsService.app.year }}</span>
                <span class="mx-2">-</span>
                <span>{{ settingsService.app.name }}</span>
                <br />
                <span>{{ settingsService.app.description }}</span>
            </div>
            <!--end::Image-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Login-->
</div>