import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { SettingsService } from '@core/services/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-callback',
  templateUrl: 'login-callback.component.html',
  styleUrls: ['./login-callback.component.scss', './three-dots.css'],
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
  stream: Subscription;
  private loadingTimeout: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    public settingsService: SettingsService
  ) {}

  public async ngOnInit() {
    // Start a 10-second timeout for loading
    this.loadingTimeout = setTimeout(() => {
      // Redirect to the home page if loading takes longer than 10 seconds
      this.router.navigate(['/dashboard']);
    }, 10000);

    this.stream = this.authService.canActivateProtectedRoutes$.subscribe(
      (x) => (yes) => {
        clearTimeout(this.loadingTimeout);
        if (yes) {
          return this.router.navigate(['/dashboard']);
        }
        else return this.router.navigate(['/login']);
      }
    );
  }

  public ngOnDestroy() {
    clearTimeout(this.loadingTimeout);
    this.stream.unsubscribe();
  }
}
