<div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
  <!--begin::Primary-->
  <div class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12"
       id="kt_brand"
       [ngClass]="brandClasses">
    <!--begin::Logo-->
    <a routerLink="/"
       class="brand-logo">
      <img alt="Logo"
           src="./assets/media/logos/logo.svg"
           class="max-h-30px" />
    </a>
    <!--end::Logo-->
  </div>
  <!--begin::Nav Wrapper-->
  <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
    <!--begin::Nav-->
    <ul class="nav flex-column"
        role="tablist">
      <!--begin::Item-->
      <li class="nav-item mb-3"
          placement="right"
          ngbTooltip="Dashboard"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="Dashboard"
           [ngClass]="{ active: currentUrl.includes('/dashboard') }"
           (click)="GoToDashboard()"
           href="JavaScript:;">
          <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Dashboard.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-sm"></span>
        </a>
      </li>

      <li class="nav-item mb-3"
          *ngIf="authService.containsPermission({title:'Hr Management',permissions:[hrMember]})"
          placement="right"
          ngbTooltip="Hr Management"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="HR Management"
           [ngClass]="{ active: currentUrl.includes('/hr')}"
           (click)="closeMe(TABS[2],'/hr')"
           data-toggle="tab"
           data-target="#kt_aside_tab_2"
           role="tab">
          <span [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-lg"></span>
        </a>
      </li>

      <!-- <li class="nav-item mb-3"
          placement="right"
          ngbTooltip="Search"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="Search"
           data-toggle="tab"
           [ngClass]="{ active: activeTabId === TABS[1] }"
           (click)="closeMe(TABS[1],'')"
           data-target="#kt_aside_tab_1"
           role="tab">
          <span [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-lg"></span>
        </a>
      </li> -->
      <!--end::Item-->
      <li class="nav-item mb-3"
          *ngIf="authService.containsPermission({title:'Time Tracking',permissions:[timeMember]})"
          placement="right"
          ngbTooltip="Project Management"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="Project Management"
           [ngClass]="{ active: currentUrl.includes('/time-tracking') }"
           (click)="closeMe(TABS[2],'/time-tracking')"
           data-toggle="tab"
           data-target="#kt_aside_tab_2"
           role="tab">
          <span [inlineSVG]="'./assets/media/svg/icons/General/Project-management.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-lg"></span>
        </a>
      </li>
      <!--begin::Item-->
      <li class="nav-item mb-3"
          *ngIf="authService.containsPermission({title:'Finance',permissions:[financeManager]})"
          placement="right"
          ngbTooltip="Finance Management"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="Finance Management"
           [ngClass]="{ active: currentUrl.includes('/finance') }"
           (click)="closeMe(TABS[2],'/finance')"
           data-toggle="tab"
           data-target="#kt_aside_tab_2"
           role="tab">
          <span [inlineSVG]="'./assets/media/svg/icons/accounts.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-lg"></span>
        </a>
      </li>

      <!-- <li class="nav-item mb-3"
          *ngIf="authService.containsPermission({title:'Hr Management',permissions:[hrMember]})"
          placement="right"
          ngbTooltip="Support Management"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="Support Management"
           [ngClass]="{ active: currentUrl.includes('/support-ticket') }"
           (click)="closeMe(TABS[2],'/support-ticket')"
           data-toggle="tab"
           data-target="#kt_aside_tab_2"
           role="tab">
          <span [inlineSVG]="'./assets/media/svg/icons/Tools/Tools.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-lg"></span>
                
        </a>
      </li> -->
      <li class="nav-item mb-3"
          *ngIf="hasAccess('read:supportticket,write:supportticket')"
          placement="right"
          ngbTooltip="Support Management"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="Support Management"
           [ngClass]="{ active: currentUrl.includes('/support-ticket') }"
           (click)="closeMe(TABS[2],'/support-ticket')"
           data-toggle="tab"
           data-target="#kt_aside_tab_2"
           role="tab">
          <span [inlineSVG]="'./assets/media/svg/icons/Tools/Tools.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-lg"></span>
                
        </a>
      </li>



      <!-- <li class="nav-item mb-3"
          *ngIf="authService.containsPermission({title:'Agent',permissions:[agentManager]})"
          placement="right"
          ngbTooltip="Agent"
          container="body">
        <a class="nav-link btn btn-icon btn-clean btn-lg"
           title="Agent"
           [ngClass]="{ active: activeTabId === TABS[2] && currentUrl ==='/agent' }"
           (click)="closeMe(TABS[2],'/agent')"
           data-toggle="tab"
           data-target="#kt_aside_tab_2"
           role="tab">
          <span [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'"
                cacheSVG="true"
                class="svg-icon svg-icon-lg"></span>
        </a>
      </li> -->
      <!--end::Item-->

    </ul>
    <!--begin::Item-->
    <!--end::Nav-->
  </div>
  <!--end::Nav Wrapper-->
  <!--begin::Footer -->
  <app-topbar class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10"></app-topbar>
  <!--end::Footer-->
  <!--end::Primary-->
</div>
<ng-container *ngIf="disableAsideSecondaryDisplay">
  <!--begin::Secondary-->
  <div class="aside-secondary d-flex flex-row-fluid">
    <!--begin::Workspace-->
    <div class="aside-workspace scroll scroll-push my-2">
      <!--begin::Tab Content-->
      <div class="tab-content">
        <!--begin::Tab Pane-->
        <div class="tab-pane p-3 px-lg-7 py-lg-5 fade"
             [ngClass]="{ 'show active': activeTabId === TABS[1] && currentUrl.includes('/dashboard') }"
             id="kt_aside_tab_1">
          <app-aside-search></app-aside-search>
        </div>
        <!--end::Tab Pane-->
        <!--begin::Tab Pane-->
        <div class="tab-pane fade"
             id="kt_aside_tab_2"
             [ngClass]="{ 'show active': activeTabId === TABS[2] && !currentUrl.includes('/dashboard') }">

          <ng-container>
            <!--begin::DYNAMIC Aside-->
            <app-aside-dynamic class="aside-menu-wrapper flex-column-fluid"
                               id="kt_aside_menu_wrapper"></app-aside-dynamic>
            <!--end::DYNAMIC Aside-->
          </ng-container>
        </div>
        <!--end::Tab Pane-->
      </div>
    </div>
  </div>
</ng-container>
