import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubMenuService {
  selectedItem = new ReplaySubject(1);

  constructor() {}
  changeItem(item: string) {
    this.selectedItem.next(item);
  }
}
