import { Component } from '@angular/core';

@Component({
  selector: 'bk-svg-summery',
  template: `
    <svg
      viewBox="0 0 14 16"
      height="20px"
      weight="20px"
      class="msportalfx-svg-palette-inherit"
      role="presentation"
      focusable="false"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      id="FxSymbol0-055"
      data-type="338"
    >
      <g>
        <title></title>
        <path
          d="M14 6.3V16H4v-3H0V0h6.7l3 3h1zM4 3h4.3l-2-2H1v11h3zm9 4h-3V4H5v11h8zm-2-1h1.3L11 4.7z"
        ></path>
      </g>
    </svg>
  `,
})
export class BkSvgSummeryComponent {}
