import {TitleCasePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IndividualConfig} from 'ngx-toastr';
import {LocalStorageDataService} from './local-storage.service';
import {MessageParams} from '@shared/services/message-params';
import {ErrorPageComponent} from '@shared/components/error-page/error-page.component';
import {AppUtilService, deepCopy} from '@shared/services/app-util.service';
import {Router} from "@angular/router";
import {NotificationModel} from "@core/model/notification";

@Injectable()
export class NotifyService {
  public config: Partial<IndividualConfig> = {
    progressBar: true,
    tapToDismiss: true,
    closeButton: true,
    enableHtml: true,
    extendedTimeOut: 2000,
    progressAnimation: 'increasing',
    positionClass: 'toast-bottom-right',
  };

  constructor(
    private toastrService: ToastrService,
    private titleCasePipe: TitleCasePipe,
    private localStorageService: LocalStorageDataService,
    private appUtilService: AppUtilService,
    private router: Router
  ) {
  }

  async success(param: MessageParams) {
    param.type = 'success';
    if (param.message) {
      this.toastrService.success(param.message, 'Success', this.config);
      return;
    }
    if (param.crud) {
      this.toastrService.success(
        await this.localStorageService.getMessage$(param).toPromise(),
        param.crud
          ? `${this.titleCasePipe.transform(
            param.object
          )} ${this.titleCasePipe.transform(param.crud)}`
          : 'Success',
        this.config
      );
    }
  }

  async error(param: MessageParams) {
    if (param.message) {
      this.toastrService.error(
        param.message,

        param.type ?? 'Error',

        this.config
      );
      return;
    }
    this.toastrService.error(
      await this.localStorageService.getMessage$(param).toPromise(),
      param.crud
        ? `${this.titleCasePipe.transform(
          param.object
        )} ${this.titleCasePipe.transform(param.crud)}`
        : 'Error',
      this.config
    );
  }

  info(param: NotificationModel) {

    const config = deepCopy(this.config);
    config.positionClass = 'toast-bottom-right';
    this.toastrService.info(param.message, 'Info', config).onTap.subscribe(() => {
      let url = this.appUtilService.getObjectUrl(param);
      this.router.navigateByUrl(url);
    });
  }

  warning(message?: string, title?: string) {
    this.toastrService.warning(message, 'Warning', this.config);
  }

  clear(toastId?: number) {
    this.toastrService.clear(toastId);
  }

  remove(toastId: number) {
    this.toastrService.clear(toastId);
  }

  staticError(message: string) {
    const config: Partial<IndividualConfig> = {
      disableTimeOut: true,
      tapToDismiss: false,
      closeButton: true,
      enableHtml: true,
      toastClass: 'ngx-toastr toast-error custom-toast',
      positionClass: 'toast-top-right',
      toastComponent: ErrorPageComponent,
    };

    this.toastrService.error(message, 'Error', config);
  }
}
