import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageViewerComponent } from '@shared/components/image-viewer/image-viewer.component';
import { ResumeViewerComponent } from '@shared/components/resume-viewer/resume-viewer.component';

@Injectable()
export class FileService {
  dataString: any;
  constructor(private dialog: MatDialog, private sanitizer: DomSanitizer) { }

  async viewImage(src: string[], index: number = 0): Promise<boolean> {
    const ref = this.dialog.open(ImageViewerComponent,{
      maxWidth: '100vw',
      width: '100vw',
      height: '100vh',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'image-popup-viewer',
    });

    const instance = ref.componentInstance;
    instance.path = src;
    instance.imageIndex = index;
    return ref.afterClosed().toPromise();
  }
  viewResume(path: string) {
    let sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://docs.google.com/gview?url=${path}&embedded=true`
    );
    const ref = this.dialog.open(ResumeViewerComponent, { minWidth: '100%', minHeight: '100%', position: { top: '0px', left: '0px' } });

    const instance = ref.componentInstance;
    instance.path = sanitizedUrl;
    return ref.afterClosed().toPromise();
  }
  Validate(fileName: string, extensions: string[]): boolean {
    if (fileName.length > 0) {
      let blnValid = false;
      for (const extension of extensions) {
        const sCurExtension = extension;
        if (
          fileName
            .substr(
              fileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        return false;
      }
    }
    return true;
  }
  getStream(file: File): any {
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.dataString = myReader.result;
    };
    myReader.readAsDataURL(file);

    return this.dataString;
  }
  dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.result.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.result.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  createDummyFile(fileName: string): File {
     return new File(['foo'], fileName.replace( /^.*[\\\/]/, ''), { type: 'image/png' });
  }
}
