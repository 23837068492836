import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@themes/metronic/app/_metronic/core';

@Component({
  selector: 'app-aside-search',
  templateUrl: './aside-search.component.html',
  styleUrls: ['./aside-search.component.scss'],
})
export class AsideSearchComponent implements OnInit {
  disableAsideSelfDisplay: boolean;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;

  constructor(private layout: LayoutService, private loc: Location) {}

  ngOnInit(): void {
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp('aside.self.display') === false;
    this.brandSkin = this.layout.getProp('brand.self.theme');
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown')
      ? '1'
      : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${
      this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''
    }`;
    // Routing
    this.location = this.loc;
  }
}
